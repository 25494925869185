.product-item-info{
	width: 100%;
	box-sizing:border-box !important;
	margin:0 auto !important;
	padding:26px !important;
	border:1px solid transparent !important;
	@include transition(0.2s);
	position:relative;

	.field.qty,
	.fieldset,
	.comment-box{
		display: none !important
	}
	.product-item-description{
		display: none;
	}
	.product-item-name,
	.product-item-name *{
		font-size: 18px !important;
		line-height: 25px !important;
		color:$text !important;
		font-family: $abril;
		text-decoration: none !important;
		&:hover{
			color:$green !important;
		}
	}
	.product-item-name{
		display: block;
		width: 100%;
		margin:0 auto 15px;
		height: 50px;
		overflow: hidden;
	}

	.product.details{
		text-align: center;
		& > .price-box{
			margin-bottom: 10px;
		}
	}
	.price-final_price,
	.special-price,
	.old-price{
		display: block;
		width: 100%;
		text-align: center;
		line-height: 1.2;
	}
	.old-price{
		@include inline;
		width: auto;
		vertical-align: middle;
	}	
	.save-price{
		@include inline;
		vertical-align: middle;
		color:#D0021B;
		font-size: 14px !important;
		margin:-5px auto  0 auto;
	}
	.price-as-configured{
		display: block;
		width: 100%;
		text-align: center;
		line-height: 1.2;
		*{
			line-height: 1.2;
		}
	}
	.price-container{
		text-align: center;
	}
	.old-price{
		margin:-5px auto 0 auto;
		*{
			font-size: 14px !important;
		}
		.price-label{
			display: none;
		}
		.price-container span span{
			text-decoration: line-through;
			&::before{
				content:'was ';
			}
		}
	}
	.price-box > .price-final_price .price,
	.special-price .price{
		font-size: 24px;
		line-height: 1.2;
		&::before{
			//content:'From ';
		}
	}
	 .price-box .price-label {
		color: #666666;
		font-size: 19px;
	}
	.product-item-photo{
		display: block;
		width: 100%;
		height: auto;
		margin-bottom: 15px;
		img{
			display: block;
			width: 100%;
			height: auto;
		}
	}
	.product-items__buttons,
	.product-item-actions{
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
		display: block;
		width: 100%;
		margin-top:10px;
		.button{
			width: 100% !important;
			display: block !important;
			margin:0 auto !important;
			max-width: 100% !important;
			height: auto !important;
			padding:5px 10px !important;
			font-size: 16px !important;
			border-width:1px !important;
			margin-bottom: 0 !important;
			position:static !important;
		}
		.actions-primary{
			width: 100%;
			display: block;
			margin:0 auto 0 !important;
			a, button, input{
				width: 100% !important;
				display: block !important;
				margin:0 auto !important;
				max-width: 100% !important;
				height: auto !important;
				padding:5px 10px !important;
				font-size: 16px !important;
				border-width:1px !important;
				margin-bottom: 0 !important;
				position:static !important;
			}
			button{
				background-color:transparent !important;
				color:$green !important;
				&:hover{
					background-color:$green !important;
					color:$white !important;
				}
			}
		}
	}
	&:hover{
		margin:0;
		padding:26px;
		border:1px solid #bbb !important;
	}
	.add-to-links,
	.action.towishlist{
		position:absolute;
		display: block;
		width: 22px;
		height: 22px;
		top:15px;
		right: 15px;
		z-index: 10;
		min-width: 0px;
		background-color:transparent;
		border:none;
		background-color:#EEEEEE;
		width:40px;
		height: 40px;
		border-radius:9999px;
		span{
			display: none;
		}
		&::before{
			display: none
		}
		&::after{
			content:'';
			display: block;
			position:absolute;
			top:50%;
			left: 50%;
			transform:translate(-50%,-50%);
			width: 22px;
			height: 20px;
			background-position:center center;
			background-repeat:no-repeat;
			background-size:22px 20px;
			background-image:url(../images/icons/product-favourite-off.svg)
		}
		&:hover::after{
			background-image: url(../images/icons/product-favourite-on.svg)
		}
	}
}