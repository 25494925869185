@import url("https://use.typekit.net/yjv0hec.css");
html, body {height: auto !important}
body {
	padding:0; 
	color:$text; 
	font-size:16px; 
	line-height:1.8;
}

a {
	
	@include transition(.2s); text-decoration:underline;
	
	&:hover {text-decoration:none}
	
}
p a, li a {text-decoration:underline; color:$text}
p a:hover, li a:hover {text-decoration:none}
ul {margin-bottom:15px}
li {margin:0}
figure {padding:0; margin:0}
address {font-style:normal}
h1,h2,h3,h4,h5,h6{
	line-height: 1.5;
	margin-bottom: 0.5em;
	font-weight: 400;
	font-family: $proxima;
	margin-top: 0;
	&:last-child{
		margin-bottom: 0;
	}
}
.title{
	font-size: 64px;
	line-height: 1.2;
}
h1{
	font-size: 48px !important;
	@media only screen and (max-width: 767px) {
		font-size: 36px !important;
	}
}
h2{
	font-size: 36px;
	@media only screen and (max-width: 767px) {
		font-size: 28px;
	}
}
h3{
	font-weight: bold;
	font-size: 36px;
	@media only screen and (max-width: 767px) {
		font-size: 28px;
	}
}
h4{
	font-size: 24px;
	font-weight: bold;
}
h5{
	font-size: 36px;
	font-weight: bold;
	font-style:italic;
	@media only screen and (max-width: 767px) {
		font-size: 28px;
	}
}
h6{
	font-size: 20px;
	font-weight:400;
}
li, p{
	font-family: $proxima;
	font-size: 16px;
	line-height: 1.8;
	margin:0 0 1em;
	@media only screen and (max-width: 767px) {
		font-size: 14px;
	}
	&:last-child{
		margin-bottom: 0;
	}
	&.small{
		font-size: 14px;
	}
	&.x-small{
		font-size: 12px;
	}
	&.xx-small{
		font-size: 10px;
	}
}
p{}
li{}
ul, ol{}
ul{}
ol{}
blockquote{}
figure:not(.full-width-image){
	position: relative;;
	font:0;
	display: block;
	width: 100%;
	@include thumbnail;
	a{
		display: block;
		position:absolute;
		top:0;
		left:0;
		right: 0;
		bottom:0;
		opacity:0;
	}
}
figcaption{}