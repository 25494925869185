.pager,
.pages{
	text-align: center;
	.toolbar-amount{
		display: none;
	}
	a,
	span,
	strong{
		margin:0;
		width: 35px;
		height: 35px;
		display: block;
		text-align: center;
		padding:0 !important;
		line-height: 35px;
		border-radius:$radius;
		color:$dgreen;
		text-decoration: none;
		@include transition(0.2s);
		box-shadow: none;
		appearance: none;
		font-weight: 400 !important;
		&:hover{
			color:$white;
			background-color:$dgreen;
		}
	}
	li{
		margin:0 8px;
		@include inline;
		vertical-align: middle;
		background-color: transparent !important;
		// DELETE TO REVERT
		position:static !important;
		&.current{
			span{
				color:$white;
				background-color: $dgreen;
				text-align: center;
			}
		}
	}
	.action{
		background-color:transparent;
		border:none;
		width: 35px;
		height: 35px;
		border-radius: $radius;
		background-color:$white !important;
		background-size:8px 14px !important;
		background-repeat:no-repeat;
		background-position:center center;
		&::before{
			display: none;
		}
		&.next{
			background-image:url(../images/pagination/next.svg);
		}
		&.previous{
			background-image:url(../images/pagination/previous.svg);
		}
	}
}