.opc-progress-bar{
	text-align: center;
	li{
		@include inline;
		vertical-align: bottom;
		width: 240px;
		height: auto;
		margin:0 2.5px;
		padding:15px 0 10px;
		font-size: 16px;
		line-height: 24px;
		a, span{
			color:#4A4A4A;
			font-size: inherit;
			line-height: inherit;
			padding-top:0;
		}
		&::before{
			display: none;
		}
		&::after{
			content:'';
			display: block;
			position: absolute;
			bottom:0;
			left: 0;
			right: 0;
			width: 100%;
			height: 5px;
			background-color: #eeeeee;
		}
		&._active,
		&._complete{
			&::after{
				background-color: $green;
			}
		}
		span{
			&::before,
			&::after{
				display: none;
			}
		}
	}
}