:root body.customer-account-login,
:root body.customer-account-create,
:root body.customer-account-forgotpassword,
:root body.account,
:root body.customer-account-create,
:root body.customer-account-logoutsuccess{
	.limiter{
		
		select{line-height: 50px;
		width: 100%;
		display: block;
		box-sizing: border-box;
		padding:0 50px 0 15px;
		font-size: 16px;
		border:none;
		box-shadow:none;
		appearance:none;
		height: 50px;
		@include transition(0.2s);
		border-radius:$radius;
		@include transition(0.2s);
		background-position: right 15px center;
		&.mage-error{
			background-color:salmon;
		}
		&:focus{
			&:placeholder,
			&::placeholder{
				opacity:0;
			}
		}
		}
	}
	.page-wrapper{
		main#maincontent{
			max-width: 1600px !important;
			width: calc(100% - 20px) !important;
			margin-left: auto !important;
			margin-right: auto !important;
			display: block !important;
			// Page
			@import "create";
			// Forgot
			@import "forgot";
			// Forms
			@import "forms";
			// Logins
			@import "login";
			// Page
			@import "page";
			// Page
			@import "recover";
			// Page
			@import "sidebar";
		}
		
	}
	
	@import "../../Magento_Catalog/styles/product-item-styles";

}
@media only screen and (max-width: 767px) {
	:root body.account .page-wrapper main.page-main#maincontent{
		padding-top: 70px;
	    padding-top: 55px;
	    position: relative;
	    margin-top: 30px;
	}
	.products-grid.wishlist{
		margin-right: auto !important;
		margin-left: auto !important;
		.box-tocart{
			display: none !important
		}
		.product-item-actions {
			width: 100% !important;
			max-width: 100% !important;
			*{
				float: none !important;
				margin-right: 0 !important;
				width: 100% !important;
				max-width: 100% !important
			}
		}
		.product-item{
			padding:20px 0 !important;
			width: calc(100% - 20px !important)
		}
		.product-item-name,
		.product-item-description,
		.product-item .price-box,
		.product-item-tooltip{
			margin-left:0 !important;
			text-align: left !important;
			*{
				text-align: left !important;
			}
		}
	} 
}
:root body.customer-account-login,
:root body.customer-account-create,
:root body.customer-account-forgotpassword,
:root body.customer-account-create,
:root body.customer-account-logoutsuccess{
	.form{
		max-width: 1024px;
		margin-left:auto;
		margin-right: auto;
	}
	.page-title-wrapper{
		text-align: center;
		margin-top: 20px;
	}

	.action.create.primary{
		*{
			font-size: inherit !important;
			line-height: inherit !important;
			font-weight: inherit !important;
			color:inherit !important;
			width: auto !important;
			height: auto !important;
			clip: none !important;
			position:static !important;
			overflow: visible !important;
			text-transform: none !important;
		}
		text-decoration: none !important;
		text-align: center !important;
		@include inline;
		vertical-align: middle !important;
		border-radius:$radius !important;
		box-sizing: border-box !important;
		min-width: 190px !important;
		padding:9px 10px !important;
		line-height: 1.8 !important;
		font-size: 16px !important;
		font-weight: bold !important;
		border-width:1px !important;
		border-style: solid !important;
		height: 50px !important;
		border-color:$green !important;
		background-color:$green !important;
		color:$white !important;
		&:hover{
			background-color:$white !important;
			color:$green !important;
		}
	}
}
:root body.wishlist-index-index .sidebar .block-wishlist{
	display: none !important
}
:root body .modals-wrapper{
	// Page
	@import "modal";
	
}

.field{
	.field-tooltip{
		top: 50%;
		transform:translateY(-50%);
		right: 10px;
	}
}