
.blog-header__filter{
	clear: both;
	display: block;
	float: right;
	select{
		margin-left: 20px;
		border:1px solid $border;
		border-radius:$radius;
		appearance:none;
		font-size: 16px;
		font-family: $proxima;
		font-weight: 400;
		color:$text;
		padding:0 15px;
		width: 192px;
		@include inline;
		vertical-align: middle;
		line-height: 41px;
		height: 43px;
		margin-left:15px;
		box-sizing: border-box;
		background-image:url(../images/icons/chevron-down.svg);
		background-size:13px 7px;
		background-repeat:no-repeat;
		background-position: right 10px center;
		&::-ms-expand {
		    display: none;
		}
	}
}