.products,
.products-grid.wishlist{
	@import "product-item-styles";
	&.products-grid ol{
		margin-left: -13px;
		margin-right: -13px;
		display: block;
		width: calc(100% + 26px);		
		font-size: 0;
		line-height: 0;
		@media only screen and (max-width: 1400px) {
			margin-left: -5px;
			margin-right: -5px;
			display: block;
			width: calc(100% + 10px);		
		}
		@media only screen and (max-width: 600px) {
			text-align: center;
		}
		.product-item{
			width: calc(33.333% - 26px);
			margin:0 13px 70px;
			@media only screen and (max-width: 1400px) {
				width: calc(33.333% - 10px) !important;
				margin:0 5px 50px !important;
			}
			@media only screen and (max-width: 1200px) {
				width: calc(50% - 10px) !important;
			}
			@media only screen and (max-width: 600px) {
				width: calc(100% - 10px) !important;
				max-width: 400px;
			}
			@include inline;
			vertical-align: top;
			.product-item-info {
				text-align: center;
				display: block;
				width: 100%;
				border-bottom:1px solid #BBBBBB !important;
				@media only screen and (max-width: 1400px) {
					padding:10px !important;
				}
			}
			.product-item-name{
				max-width: 250px;
				margin-left: auto;
				margin-right: auto;
				text-align:center;
			}
		}
	}
	&.products-list ol{
		.product-item{
			margin-bottom: 30px;
			padding-bottom: 30px;
			border-bottom:1px solid $border;
			position:relative;
			.action.towishlist{
				top: 0;
				left: 10px;
				right: auto;
				@media only screen and (max-width: 800px) and (min-width: 767px), (max-width: 500px) {
					right: 20px;
					left: auto;
				}
			}
			.product-item-info{
				@include clearfix;
				padding:0 0 0 0;
				position:relative;
				border-bottom:1px solid #BBBBBB !important;
			}
			.product-item-photo{
				width: 200px;
				margin-right: 20px;
				display: block;
				float: left;
				padding:0;
				margin-bottom: 0;
				@media only screen and (max-width: 800px) and (min-width: 767px), (max-width: 500px) {
					float: none;
					margin:0 auto 20px;
					display: block;
				}
			}
			.product.details{
				max-width: 100%;
				width: calc(100% - 220px);
				float: left;
				display: block;
				@media only screen and (max-width: 800px) and (min-width: 767px), (max-width: 500px) {
					max-width: 300px;
					width: 100%;
					float: none;
					margin:0 auto;
				}
				.product-item-name,
				.price-box{
					text-align: left;
					display: block;
					max-width: 100%;
					height: auto;
					@media only screen and (max-width: 800px) and (min-width: 767px), (max-width: 500px) {
						text-align: center;
					}
					*{
						max-width: 100%;
						text-align: left;
						height: auto;
						@media only screen and (max-width: 800px) and (min-width: 767px), (max-width: 500px)  {
							text-align: center;
						}
					}
				}
				.product-items__buttons{
					margin-left: 0;
					@media only screen and (max-width: 800px) and (min-width: 767px), (max-width: 500px) {
						margin-left: auto;
					}
				}
				@media only screen and (max-width: 959px) {}

			}
		}
	}
	& + .toolbar-products .modes{
		display: none;
	}
}