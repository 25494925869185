.accordion{
	margin-bottom: 70px;
	border-top: 1px solid $border;
	margin-left: auto;
	margin-right: auto;
	@media only screen and (max-width: 767px) {
		margin-bottom: 50px;
	}
	h3.accordion-title{
		border-bottom:1px solid $border;
		position: relative;
		display: block;
		padding:20px 0;
		font-size: 18px;
		line-height:30px;
		margin:0;
		display: block;
		width: 100%;
		text-align: left;
		font-family: $abril;
		color:#4A4A4A;
		cursor:pointer;
		transition-delay: 0.4s;
		&::before,
		&::after{
			display: block;
			content:'';
			background-color: #459280;
			height: 3px;
			width: 18px;
			display: block;
			position:absolute;
			top:50%;
			right: 10px;
			@include transition(0.2s);
			transform:translateY(-50%);
			transform-origin:center center;
		}
		&::after{

			transform:translateY(-50%) rotate(90deg);
		}
		&.open{
			border-bottom:1px solid transparent;
			transition-property: border-color;
			transition-delay: 0s;
			&::after{
				transform:translateY(-50%)
			}
		}
	}
	&__content{
		border-bottom:1px solid $border;
		display: none;
		padding-bottom:50px;
	}
}

