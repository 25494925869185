.review-add{
	padding-bottom:40px;
	border-bottom:1px solid $border !important;
	margin-bottom: 40px !important;
	.legend.review-legend{
		@include inline;
		font-family: $abril;
		font-size: 20px;
		line-height: 1.8 !important;
		color:$green;
		font-weight: 400;
		*{
			@include inline;
			font-family: $abril;
			font-size: 20px;
			line-height: 1.8 !important;
			color:$green;
			font-weight: 400;
		}
		span{
			color:$dgreen;
			margin-right: 20px;
		}

	}
	form{
		width: 100%;
		display: block;
		max-width: 100%;
		input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
		select{
			line-height: 50px;
			width: 100%;
			display: block;
			box-sizing: border-box;
			padding:0 15px;
			font-size: 16px;
			border:none;
			box-shadow:none;
			appearance:none;
			height: 50px;
			@include transition(0.2s);
			border-radius:$radius;
			@include transition(0.2s);
			background-position: right 15px center;
			&.mage-error{
				background-color:salmon;
			}
			&:focus{
				&:placeholder,
				&::placeholder{
					opacity:0;
				}
			}
		}
		input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
		select{
			line-height: 48px;
			width: 100%;
			display: block;
			box-sizing: border-box;
			padding:0 15px;
			font-size: 16px;
			border:none;
			box-shadow:none;
			appearance:none;
			height: 50px;
			@include transition(0.2s);
			border-radius:$radius;
			border:1px solid $border;
			@include transition(0.2s);
			background-position: right 15px center;
			&.mage-error{
				background-color:salmon;
			}
			&:focus{
				&:placeholder,
				&::placeholder{
					opacity:0;
				}
			}
		}
		textarea{
			line-height: 1.8;
			width: 100%;
			display: block;
			box-sizing: border-box;
			padding:10px 15px;
			font-size: 16px;
			border:none;
			box-shadow:none;
			appearance:none;
			height: auto;
			@include transition(0.2s);
			border-radius:$radius;
			border:1px solid $border;
			@include transition(0.2s);
			background-position: right 15px center;
		}
		.review-control-vote label:before, 
		.review-control-vote:before{
			font-size: 16px !important;
		}
		.review-field-rating .control{
			margin-top: 0 !important;
		}
		.field.choice{
			margin-bottom: 0 !important;
		}
		legend.label{
			color:$green;
			font-size: 16px;
			margin-bottom: 0 !important;
			line-height: 1.2 !important;
		}
	}
	.review-control-vote label:before{
		color:#e2d055;
	}
}
.review-items{
	.review-ratings .rating-label, 
	.customer-review .review-details .customer-review-rating .rating-label{
		color: grey;
		font-weight: bold;
		font-size: 16px !important;
	}
	.review-item{
		padding:40px 0;
		&:first-child{
			padding-top:0 !important;
		}
		&:last-child{
			padding-bottom: 0 !important;
			border-bottom:none !important;
		}
	}
	.review-title {
		margin-bottom:20px;
		font-size: 18px;
		line-height: 22px;
		font-weight: bold;
		color:$text;
	}
	.review-ratings{
		float:right;
		margin-right: 135px;
		@media only screen and (max-width: 767px) {
			float: none;
			margin-right: auto;
			width: 100%;
			display: block;
			margin-bottom: 10px;
		}
	}
	.review-content {
		margin-bottom:20px;
		font-size:16px;
		line-height: 1.8;
		color:$text;
	}
	.review-details {
		margin-bottom:0;
		*{
			font-size:16px !important;
			line-height: 1.8 !important;
			color:$text !important;
		}
	}
	.review-ratings~.review-content, 
	.review-ratings~.review-details{
		margin-left: 0;
		margin-right: 320px;
		max-width: 1029px;
		@media only screen and (max-width: 767px) {
			max-width: 100%;
			margin-right:0;
		}
	}
}
.review-toolbar{
	display: none !important;
}