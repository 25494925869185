.faqs{

	header{
		margin-bottom: 50px;
		max-width:1060px;
		h1,h2,h3,h4,h5,h6{
			margin-bottom: 0.1em;
			font-family: $abril;
			color:$green;
		}
	}

	&__wrapper{
		@include clearfix;
		&--left{
			@media only screen and (min-width: 961px) {
				max-width: 384px;
				width: calc(33.33333% - 20px);
				float: left;
			}
			@media only screen and (max-width: 960px) {
				margin-bottom: 50px;
			}

				&:not(.selected) ul li:first-child a{
					font-weight: bold;
				}
			ul{
				@include no-format;
			}

			li{
				display: block;
				width: 100%;
				border-bottom:1px solid $border;
				margin-bottom: 0;
				&:first-child{
					border-top:1px solid $border;
				}
			}
			a{
				display: block;
				width: 100%;
				text-decoration: none;
				padding:19px 0;
				color:$text;
				font-size:18px;
				line-height: 45px;
				font-family: $abril;
				color:$text !important;
				&:hover,
				&.active{
					font-weight: bold;
					font-size: 20px;
				}
			}
		}
		&--right{
			.tab{
				display: none;
				&:first-child{
					display: block;
				}
			}
			@media only screen and (min-width: 961px) {
				width: 66.66666%;
				float: right;
				max-width: 1060px;
			}
			.accordion{
				margin-bottom: 0;
			}
			h3{
				font-family: $proxima;
				font-size: 20px;
				line-height: 33px;
				font-weight: bold;
				padding:25px 0;
				&::before{
					width: 17px;
					height: 9px;
					background-size: 17px 9px;
					background-position: center center;
					background-repeat: no-repeat;
					background-image: url(../images/icons/accordion-closed.svg);
					background-color:transparent;
					transform:translateY(-50%) !important;
					@include transition(0s);
				}
				&::after{
					display: none;
				}
				&.open{
					&::before{
						background-image: url(../images/icons/accordion-open.svg);
					}
					& + .accordion__content{
						padding-bottom: 30px !important;
					}
				}
			}
		}
	}
}