#maincontent{
	.block.crosssell{
		width: 100%;
		float: none;
		display: block !important;
	}
	.flexslider{
		// width: 100%;
		// margin-left:0 !important;
		// margin-right:0 !important;
		// margin-top:0 !important;
		// border:none !important;
	}
	.product-wrap--secondary__related{
		.flexslider{
			margin:0 !important;
		}
		.container{
			max-width: 100%;
			width: 100%;
			.recently-viewed,
			#recently-viewed,
			.block.related,
			.block.upsell{
				max-width: 1600px !important;
				width: calc(100% - 20px);
				margin:0 auto;
			}
		}
	}
	.product-wrap--secondary__related,
	.block-title,
	header{
		text-align: center;
		margin-bottom: 40px;
		h1,h2,h3,h4,h5,h6, strong{
			color:$green;
			font-family: $abril;
			font-size: 28px;
			line-height: 1.8;
			font-weight: 400;
			display: block;
			width: 100%;
			text-align: center;
		}
	}
	.carousel--products--blog,
	.flexslider__rel-products,
	.block-viewed-products-grid,
	.related_flex,
	#viewed-container{

		.flexslider{
			border:none !important;
		}
		@import "../../../../Magento_Catalog/styles/product-item-styles";
		.product-item{
			margin:0 20px 0 0 !important;
			padding-bottom:10px !important;
			@media only screen and (max-width: 767px) {
				margin:0 10px 0 0 !important;
			}
		}
		.product-item-info{
			// border:none !important;
			// box-shadow:none !important;
			max-width: 380px !important;
			width: 100% !important;
			margin:0 auto !important;
			padding-bottom: 0 !important;
			padding-top:0 !important;
			padding-left:0 !important;
			padding-right:0 !important;
			&:hover{
				// border:none !important;
				// box-shadow:none !important;
			}
			.product-item-name{
				max-width: 250px;
				margin-left: auto;
				margin-right: auto;
				text-align:center;
			}
		}
		.flex-direction-nav li{
			a{
				position:absolute;
				z-index: 100;
				top:50%;
				transform:translateY(-50%);
				width: 13px;
				height: 26px;
				background--size:28px 21px;
				background-repeat: no-repeat;
				background-position: center center;
				background-repeat: no-repeat;
				background-color:transparent;
				border:none !important;
				font-size: 0;
				line-height: 0;
				text-indent: -9999px;
				overflow: hidden;
				box-shadow:none !important;
				appearance:none !important;
				opacity:1 !important;
				&::before,
				&::after{
					display: none;
				}
			}
			&.flex-nav-next a{
				background-image: url(../images/icons/chevron-right-large.svg);
				right: -50px !important;
				@media only screen and (max-width: 1750px) {
					right: 0px !important;
				}
			}
			&.flex-nav-prev a{
				background-image: url(../images/icons/chevron-left-large.svg);
				left: -50px !important;
				@media only screen and (max-width: 1750px) {
					left: 0px !important;
				}
			}
		}
	}
	.block.related .block-content.content,
	.block.upsell .block-content.content,
	#viewed-container{
		margin-bottom: 120px !important;
		@media only screen and (max-width: 1750px) {
			margin-bottom: 60px !important;
		}
		position:relative;
	}
	.block-viewed-products-grid{
		margin-bottom: 60px !important;
		@media only screen and (max-width: 1750px) {
			margin-bottom: 30px !important;
		}
		.product-item-actions{
			display: none !important;
		}
	}

	.carousel--products--blog{
		background-color:#FAFAFA;
		padding: 0 0 65px;
		margin-bottom: 32px;
		max-width: 100%;
		margin: 0 auto 30px;
		width: 100%;
		.flexslider{
			margin-bottom: 0 !important;
			background-color:transparent;
			border:none !important;
		}
	}
}

