.block.filter{
	.block-subtitle.filter-current-subtitle{
		padding:0 !important;
	}
	@media only screen and (max-width: 767px) {
		margin-bottom: 20px;
		.block-title.filter-title{
			padding:0  0 10px 0!important;
			position: relative;
			box-sizing:content-box;
			strong{
				margin:0 !important;
				&::after{
					display: none;
				}
			}
		}
		&:not(.active) .block-title.filter-title{
			display: block !important;
			background-color:$dgreen;
			box-sizing:border-box;
			padding:0 10px !important;
			width: 100%;
			height: 55px;
			position:relative;
			top:0;
			left: 0;
			right: 0;
			bottom:0;
			text-align: left !important;
			border:none !important;
			appearance:none !important;
			box-shadow:none !important;
			&::before{
				display: none !important
			}
			strong{				
				appearance:none !important;
				box-shadow:none !important;
				position:absolute;
				display: block;
				width: 100%;
				box-sizing:border-box;
				text-decoration:none;
				padding: 0 10px;
				left: 0;
				right: 0;
				bottom:0;
				top:0;
				line-height:55px;
				color:$white;
				font-size: 	16px;
				text-align: left;
				margin-bottom: 0 !important;
				@include transition(0s);
				display: block !important;
				background-color:$dgreen;
				box-sizing:border-box;
				padding:0 10px !important;
				width: 100%;
				height: 55px;
				position:relative;
				top:0;
				left: 0;
				right: 0;
				bottom:0;
				text-align: left !important;
				border:none !important;
				&::before{
					content:'';
					display: block;
					position:absolute;
					right:10px;
					top:50%;
					transform:translateY(-50%);
					background-size:15px 15px;
					width: 15px;
					height: 15px;
					background-position:center center;
					background-repeat:no-repeat;;
					background-image:url(../images/header/hamburger.svg);
				}
			}
		}
		&.active .block-title.filter-title{
			display: block !important;
			background-color:$dgreen;
			box-sizing:border-box;
			padding:0 10px !important;
			width: 100%;
			height: 55px !important;
			position:fixed;
			top:0;
			left: 0;
			right: 0;
			bottom:0;
			z-index: 999999;
			strong{
				position:absolute;
				display: block;
				width: 100%;
				box-sizing:border-box;
				text-decoration:none;
				padding: 0 10px;
				left: 0;
				right: 0;
				bottom:0;
				top:0;
				line-height:55px;
				color:$white;
				font-size: 	16px;
				text-align: left;
				margin-bottom: 0 !important;
				@include transition(0s);
				&::before{
					content:'';
					display: block;
					position:absolute;
					right:10px;
					top:50%;
					transform:translateY(-50%);
					background-size:15px 15px;
					width: 15px;
					height: 15px;
					background-position:center center;
					background-repeat:no-repeat;;
					background-image:url(../images/header/close.svg);
				}
			}
		}
		.content.block-collapsible-nav-content{
			&.active{
				position:fixed;
				top:0;
				left: 0;
				right: 0;
				bottom:0;
				display: block;
				width: 100%;
				height: 100vh;
				box-sizing:border-box;
				padding-top:75px !important;
				padding-bottom:10px !important;
				ul{
					height:calc(100vh - 85px);
					overflow: auto;
					display: block;
					width: 100%;
				}
			}
		}
	}

	.filter-content{
		> .block-subtitle{
			font-size: 16px;
			line-height: 55px;
			position:relative;
			box-sizing:border-box;;
			height: 55px;
			padding:0 15px;
			background-color:#ecebec;
			color:$dgreen;
			position:relative;
			border:none !important;
			font-size: 15px;
			font-weight: 400;
			&::before{
				content:'';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 13px 15px 0 15px;
				border-color: #ecebec transparent transparent transparent;
				position:absolute;
				top:calc(100% - 1px);
				left: 50%;
				transform:translateX(-50%);
			}
		}
		.filter-actions{
			display: block;
			width: 100%;
			text-align: left;
			font-size: 16px;
			padding:0 15px;
			box-sizing:border-box;;
			margin: 0 0 10px 0;
			a{
				color:$dgreen;
				text-decoration: none;
				font-size:16px;
				line-height: 1.8;
				margin:0 10px 10px 0;
				&:hover{
					color:$green;
				}
			}
		}
		.filter-current{
			padding:0 15px;
			width: 100%;
			box-sizing:border-box;
			margin:0 auto;
			@media only screen and (max-width: 767px) {
				margin:20px auto;
				position:relative !important;
				padding:0;
				border:none;
				span.block-subtitle{				
					appearance:none !important;
					box-shadow:none !important;
					border:none;
					position:relative;
					display: block;
					width: 100%;
					box-sizing:border-box;
					text-transform: none;
					text-decoration:none;
					padding: 0 20px;
					line-height:55px;
					height: 55px;
					background-color: $dgreen;
					color:$white !important;
					font-size: 	16px;
					text-align: left;
					margin-bottom: 0 !important;
					@include transition(0s);
					&::before{
						content:'';
						display: block;
						position:absolute;
						right:20px;
						top:50%;
						transform:translateY(-50%);
						background-size:15px 15px;
						width: 15px;
						height: 15px;
						background-position:center center;
						background-repeat:no-repeat;;
						background-image:url(../images/header/hamburger.svg);
					}
				}
				.items{
					padding:0 15px !important;
					box-sizing:border-box !important;

				}
				&.active {
					margin:20px auto 20px;
					display: block !important;
					box-sizing:border-box;
					span.block-subtitle{
						margin:0 0 20px 0 !important;
						&::before{
							background-size:15px 15px;
							width: 15px;
							height: 15px;
							background-image:url(../images/header/close.svg);
						}
					}
				}
			}
			.block-subtitle{
				font-size: 18px;
				line-height:43px;
				display: block;
				width: 100%;
				padding:0;
				margin-bottom: 15px;
				box-sizing:border-box;
				font-weight: 600;
				color:$dgreen;
				@media only screen and (max-width: 767px) {
					font-size: 18px;
					line-height:43px;
					display: block;
					width: 100%;
					padding:0;
					margin-bottom: 15px;
					box-sizing:border-box;
					font-weight: 600;
					color:$dgreen;
				}
			}
			ol{
				@include no-format;
				margin-bottom: 20px;
				li{
					margin:0 auto 0;
					position:relative;
					padding-left:30px;
				}
				span{
					font-size: 16px;
					line-height: 43px;
					font-weight: 400;
					font-family: $proxima;
					color:$text;
				}
				a{
					top: 50%;
					left: 0;
					line-height: 43px;
					margin-right: 13px;
					width: 16px;
					height: 16px;
					box-sizing:border-box;;
					border:1px solid $border;
					transform:translateY(-50%);
					border-radius:$radius;
					margin:0;
					&::before{
						display: none;
					}
					&::after{
						opacity:0.7;
						@include transition(0.2s);
						content:'';
						display: block;
						position: absolute;
						top: 50%;
						left: 50%;
						transform:translate(-50%,-50%);
						width: 10px;
						height: 10px;
						background-size: 10px 10px;
						background-repeat:no-repeat;
						background-position:center center;
						background-image:url(../images/input/crossed.svg);
					}
					&:hover::after{
						opacity:1;
					}
				}

			}
		}
	}
	.filter-options{
		background-color:rgba(238, 238, 238, .5);
		padding-top:1px;
		.am-filter-items-attr_price{
			margin:20px auto 0;
			max-width: 100%;
			width: calc(100% - 30px);
		}

		.filter-options-item{
			border-bottom:none;
			position:relative;
			&:not(:last-child)::after{
				content:'';
				display: block;
				position:absolute;
				bottom:0;
				left: 15px;
				right: 15px;
				height: 1px;
				width: calc(100% - 30px);
				background-color:#BBBBBB;
			}
			.filter-options-title{
				font-size: 18px;
				line-height: 43px;
				color:$dgreen;
				box-sizing:border-box;
				width: 100%;
				padding-left:15px;
				padding-right:15px;
				font-weight:600;
				text-transform:none;
				position:relative;
				padding-top:0;
				margin-top: 10px;
				&::after,
				&::before{
					content:'';
					display: block;
					width: 18px;
					height: 18px;
					background-position:center center;
					background-repeat:no-repeat;
					transform:translateY(-50%) !important;
					position:absolute;
					right:15px;
					top:50%;
					@include transition(0.2s);
				}
				&::after{
					background-size:18px 18px;
					background-image:url(../images/icons/filter-closed.svg);
				}
				&::before{
					background-size:18px 2px;
					background-image:url(../images/icons/filter-open.svg);
				}
			}
			&.active .filter-options-title::after{
				opacity:0;
			}
		}
		.filter-options-content{
			padding:0 !important;
		}
		.items {
			padding:0;
			li{
				margin:0 !important;
			}
			a:not(.ui-slider-handle){
				padding-left:0;
				text-decoration: none;
				font-weight: 400 !important;
				font-size: 16px !important;
				line-height: 43px !important;
				margin:0 !important;
				display: block;
				width: 100%;
				padding:0 15px !important;
				box-sizing:border-box;
				position:relative;
				input{
					opacity:0;
					z-index: 2;
					position:absolute;
					display: block;
					width:100%;
					height: 100%;
					top:0;
					left: 0;
					right: 0;
					bottom:0;
					cursor:pointer;
					margin:0 !important;
					&:hover + .label::before{
						background-color:$green;
					}
					&:checked + .label::before{
						background-color:$green;
						background-image:url(../images/input/checked.svg);
					}
				}
				.label{
					display: block;
					width: 100%;
					box-sizing:border-box;
					padding-left:30px;
					position:relative;
					&::before{
						content: '';
						display: block;
						width: 16px;
						height: 16px;
						box-sizing: border-box;
						border:1px solid $border;
						border-radius: $radius;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						@include transition(0.2s);
						background-size: 10px 8px;
						background-repeat: no-repeat;
						background-position: center center;
						margin:-1px 0 0 0 !important;
					}
				}
				.count{
					display: none;
				}
			}
			a.ui-slider-handle{
				background-color:$dgreen;
			}
		}
	}
}