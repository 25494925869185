.container{
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	width:calc(100% - 20px);
}
#maincontent{
	max-width: 100%;
	padding-left: 0;
	padding-right: 0;
	margin-left: 0;
	margin-right: 0;
}
// .columns{
// 	margin-left: -20px;
// 	margin-right: -20px;
// 	.col{
// 		margin:0 20px 20px;
// 	}
// 	.third{}
// 	.half{}
// 	.quarter{}
// 	.fifth{}
// }



// Magento Base Blocks

.rating-summary {
	margin-right:20px !important;
	span::before{
		color:#e2d055 !important;
	}
}
.reviews-actions a{
	color:$text;
	&:hover{
		color:$green;
	}
}

