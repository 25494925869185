:root body .page-wrapper .page-footer{
	padding-bottom:0 !important;
	margin-top: 0 !important;
	.fat-media p{
		margin-bottom: 0 !important;
		a{
			text-decoration: none;
			color:$black;
			&:hover{
				color:$green;
			}
		}
		span,
		img{
			@include inline;
			vertical-align: middle;
			margin: 5px;
		}
	}
	&__top{
		padding:50px 0;
		@include clearfix;
		background-color: $mgreen;
		.container{
			max-width: 1100px;
		}
		@media only screen and (max-width: 767px) {
			padding:25px 0;
		}
	}
	&__middle{
		padding:50px 0;
		background-color: $dgreen;
			@media only screen and (max-width: 767px) {
				padding:25px 0;
			}
	}
	&__cols{
		margin-left: -10px;
		margin-right: -10px;
		font-size: 0;
		line-height: 0;
		.block-blog .block-title{
			padding-bottom:0;
			margin-bottom: 0;
		}
		.block{
			margin-bottom: 0 !important;
		}
		&__col{
			margin:0 10px;
			width: calc(25% - 20px);
			@include inline;
			vertical-align: top;
			@media only screen and (max-width: 767px) {
				.block{
					margin-bottom: 0;
				}
				.block-content{
					padding:5px 0;
					border-top:1px solid rgba(255,255,255,0.2);
					display: none;
					&.open{
						display: block;
					}
				}
				width: calc(100% - 20px);
				border-bottom:1px solid rgba(255,255,255,0.5);
				&:first-child{
					border-top:1px solid rgba(255,255,255,0.5);
				}
			}
			*{
				color:$white;
				text-decoration: none;
				a:hover{
					color:$lgreen;
				}
			}
			ul{
				@include no-format;
			}
			li{
				margin-bottom: 0;
				font-size: 16px;
				line-height: 29px;
				@media only screen and (max-width: 1050px) {
					font-size: 14px;
					line-height: 29px;
					margin-bottom: 0px;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		.block-title{
			border:none;
			*{
				font-size: 20px;
				line-height: 33px;
				font-weight: bold;
				padding-bottom:20px;
				@media only screen and (max-width: 1050px) {
					padding-bottom: 10px;
				}
				display: block;
				@media only screen and (max-width: 1050px) {
					font-size: 18px;
					line-height: 33px;
				}
				@media only screen and (max-width: 850px) {
					font-size: 16px;
				}
				@media only screen and (max-width: 767px) {
					font-size: 20px;
					display: block;
					position:relative;
					padding:10px 0;
					line-height: 30px;
					cursor:pointer;
					&::before{
						content:'';
						display: block;
						position:absolute;
						right: 0;
						top:50%;
						transform: translateY(-50%);
						width: 10px;
						height: 5px;
						display: block;
						background-position:center center;
						background-repeat:no-repeat;;
						background-size: 10px 5px;
						background-image:url(../images/footer/chevron.svg);
					}
					&.open::before{
						transform:rotate(180deg) translateY(50%);
					}
				}
			}
		}
	}
	&__copyright{}
	&__cards{}
	&__bottom{
		padding:35px 0 67px;
		text-align:center;
		h6{
			margin-bottom: 25px;
			display: block;
			width: 100%;
			text-align: center;
			font-size: 16px;
		}
		img{
			margin-bottom:30px;
			display: block;
			max-width: 100%;
			height:auto;
			margin:0 auto 30px; 
		}
		p{
			max-width: 861px;
			margin:0 auto 40px;
			font-size: 12px;
		}
	}
	&__social{
		*{
			color:$white;
			text-decoration: none !important
		}
		h1,h2,h3,h4,h5,h6,p{
			max-width: 410px;
			display: block;
			width: 100%;
			font-family: $abril;
			font-weight: 400;
			margin-bottom: 15px;
		}
		h1,h2,h3,h4,h5,h6{
			font-size: 36px;
			line-height: 55px;
			@media only screen and (max-width: 1050px) {
				font-size: 28px;
				line-height: 39px;
			}
			@media only screen and (max-width: 767px) {
				text-align: center;
				margin-left: auto;
				margin-right:auto;
				margin-bottom: 20px;
			}
		}
		@media only screen and (max-width: 767px) {
			p{	
				text-align: center;
				margin-left: auto;
				margin-right:auto;
			}
		}
		ul{
			@include no-format;
			padding-top: 20px;
			display: block;
			width: 100%;
			@media only screen and (max-width: 767px) {
				margin-bottom: 50px;
				text-align: center;
				padding-top:30px;
			}
			li{
				@include inline;
				vertical-align: middle;
				margin-right: 20px;
				margin-bottom: 0;
				&:last-child{
					margin-right: 0;
				}
				a{
					font-size: 0;
					line-height: 0;
					display: block;
					width: 40px;
					height: 40px;
					text-indent:-999px;
					position: relative;

					&::before{
						content:'';
						font-family: 'FontAwesome';
						position:absolute;
						font-size: 37px;
						text-indent: 0;
						display: block;
						top:50%;
						left: 50%;
						transform:translate(-50%,-50%);
					}
				}
			}
			.social{
				&__facebook a::before{
					content:'\f09a';
				}
				&__twitter a::before{
					content:'\f099'
				}
				&__pinterest a::before{
					content:'\f231'
				}
				&__instagram a::before{
					content:'\f16d'
				}
				&__google a::before{
					content:'\f0d5'
				}
			}
		}
	}
	&__newsletter{
		.newsletter{
			margin-bottom: 0 !important;
			display: block !important;
			width: 100%;
			p{
				color:$white;
				font-size: 12px;
				line-height: 22px;
				margin-top: 20px;
				*{
					color:inherit;
				}
				a:hover{
					color:$lgreen !important;
				}
			}
		}
		form{
			display: block !important;
			width: 100%;
			.field,
			.actions{
				display: block;
				width: 100%;
				height: auto;
				clear: both;
				float: none;
			}
		}
		.control::before{
			display: none;
		}
		.control--checkbox{
			position:relative;
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: 25px;
			input{
				position:absolute;
				cursor:pointer;
				display: block;
				width: 100%;
				z-index: 2;
				opacity:0;
				& + label{
					display: block;
					position:relative;
					padding-left:45px;
					width: 100%;
					text-align: left;
					z-index: 1;
					box-sizing:border-box;
					font-size: 12px;
					line-height: 22px;
					color:$white;
					&::before{
						content:'';
						display: block;
						position:absolute;
						top:0;
						left: 1px;
						border:1px solid $border;
						border-radius:$radius;
						background-color:$white;
						width: 26px;
						height: 26px;
						box-sizing:border-box;
						background-size:15px 15px;
						background-repeat:no-repeat;
						background-position: center center;
					}
				}
				&:checked + label{
					&::before{
						background-image:url(../images/input/crossed.svg);
					}
				}
			}

		}
		div.mage-error{
			display: none !important;
		}
		input[type="submit"],
		button{
			min-width: 192px !important;

			@media only screen and (max-width: 767px) {
				width: 100%;
			}
		}
		input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
		select{
			line-height: 50px;
			width: 100%;
			display: block;
			box-sizing: border-box;
			padding:0 15px;
			font-size: 16px;
			border:none;
			box-shadow:none;
			appearance:none;
			height: 50px;
			@include transition(0.2s);
			border-radius:$radius;
			@include transition(0.2s);
			background-position: right 15px center;
			margin-bottom: 20px;
			&.mage-error{
				background-color:salmon;
			}
			&:focus{
				&:placeholder,
				&::placeholder{
					opacity:0;
				}
			}
		}
	}
	@media only screen and (min-width: 768px) {
		&__social,
		&__newsletter{
			width: 48%;
			max-width: 520px;
		}
		&__social{
			float: left;
		}
		&__newsletter{
			float: right;
		}
	}
}