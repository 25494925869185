.post-list-description{
	display: none;
}

.blog-header{
	max-width: 1600px;
	margin-left:auto;
	margin-right: auto;
	display: block;
	width: calc(100% - 20px);
	margin-bottom: 40px;
	@include clearfix;
	h1,h2,h3,h4,h5,h6{
		margin-bottom: 0.5em;
		font-family: $abril;
		color:$green;
	}
	h1,h2,h3,h4,h5,h6,p,li{
		max-width: 1060px;
	}
	p:last-child{
		margin-bottom: 0;
	}
}