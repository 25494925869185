.gallery{
	max-width:1600px;
	display: block;
	margin:0 auto 60px !important;
	border:none !important;
	display: block;
	width: calc(100% - 20px);
	ul{
		@include no-format;
	}
	.flex-direction-nav li{
		a{
			position:absolute;
			z-index: 100;
			top:50%;
			transform:translateY(-50%);
			width:42px;
			height: 42px;
			background--size:24px 15px;
			background-repeat: no-repeat;
			background-position: center center;
			background-repeat: no-repeat;
			background-color:transparent;
			border:none !important;
			font-size: 0;
			line-height: 0;
			text-indent: -9999px;
			overflow: hidden;
			box-shadow:none !important;
			appearance:none !important;
			opacity:1 !important;
			background-color:$white !important;
			border-radius:999px;
			@include transition(0.2s);
			@media only screen and (max-width: 1750px) {
				width: 26px;
				height: 26px;
				background-size:12px auto;
			}
			&::before,
			&::after{
				display: none;
			}
		}
		&.flex-nav-next a{
			background-image: url(../images/gallery//arrow-right.svg);
			right: 35px !important;
			&:hover{
				background-position: right 3px center;
			}
			@media only screen and (max-width: 1750px) {
				right: 10px !important;
			}
		}
		&.flex-nav-prev a{
			background-image: url(../images/gallery//arrow-left.svg);
			left: 35px !important;
			&:hover{
				background-position: left 3px center;
			}
			@media only screen and (max-width: 1750px) {
				left: 10px !important;
			}
		}
	}
}