/* ==========================================================================

/* ==========================================================================
   1. Reset
   ========================================================================== */
   // BASE
   @import "scss/base";
   // fonts
   @import "scss/fonts";
   // footer
   @import "scss/mixins";
   // form
   @import "scss/normalize";
   // hamburger
   @import "scss/responsive";
   // header
   @import "scss/variables";
   // typography
   @import "scss/font-awesome";


/* ==========================================================================
   2. Magento Styles
   ========================================================================== */

   // Customer Accounts
   @import "../Magento_Customer/styles/module.scss";

   // Catalog
   @import "../Magento_Catalog/styles/module.scss";

   // Checkout
   @import "../Magento_Checkout/styles/module.scss";
   
   // Wordpress Integration
   @import "../FishPig_WordPress/styles/module.scss";

   // Checkout
   //@import "../Magento_Checkout/styles/module.scss";


/* ==========================================================================
   3. Global STYLES
   ========================================================================== */
   // BASE
   @import "scss/global/base";
   // fonts
   @import "scss/global/block-classes";
   // footer
   @import "scss/global/footer";
   // form
   @import "scss/global/form";
   // hamburger
   @import "scss/global/hamburger";
   // header
   @import "scss/global/header";
   // typography
   @import "scss/global/typography";
   // footer
   @import "scss/global/buttons";
   // footer
   @import "scss/global/breadcrumbs";
   // footer
   @import "scss/global/messages";

/* ==========================================================================
   4. Browser Fixes
   ========================================================================== */

/* ie9 */
.canvas.no-websockets {
	
}

/* ie8 */
.hashchange.no-canvas {

	
}

/* ie7 */
.no-hashchange {

	
}

/* ==========================================================================
   5. Plugins
   ========================================================================== */
// Fancybox
@import "scss/vendor/fancybox";
// Flexslider
@import "scss/vendor/flexslider";
// Animate
@import "scss/vendor/animate";



/* ==========================================================================
   6. Responsive
   ========================================================================== */

@import "scss/responsive";