:root body .page-wrapper .carousel{
	max-width: 1600px;
	width: calc(100% - 20px);
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 60px;
	position:relative;
	.flex-direction-nav li{
		a{
			position:absolute;
			z-index: 100;
			top:50%;
			transform:translateY(-50%);
			width:28px;
			height: 21px;
			background--size:28px 21px;
			background-repeat: no-repeat;
			background-position: center center;
			background-repeat: no-repeat;
			background-color:transparent;
			border:none !important;
			font-size: 0;
			line-height: 0;
			text-indent: -9999px;
			overflow: hidden;
			box-shadow:none !important;
			appearance:none !important;
			opacity:1 !important;
			@media only screen and (max-width: 1750px) {
				background-color:$white !important;
				border-radius:999px;
				width: 26px;
				height: 26px;
				// background-size:12px 9px;
			}
			&::before,
			&::after{
				display: none;
			}
		}
		&.flex-nav-next a{
			background-image: url(../images/icons/carousel-right.svg);
			right: -70px !important;
			@media only screen and (max-width: 1750px) {
				right: 30px !important;
				background-image: url(../images/hero/mobile/right.svg)
			}
			@media only screen and (max-width: 1750px) {
				right: -13px !important;
			}

		}
		&.flex-nav-prev a{
			background-image: url(../images/icons/carousel-left.svg);
			left: -70px !important;
			@media only screen and (max-width: 1750px) {
				left: 30px !important;
				background-image: url(../images/hero/mobile/left.svg)
			}
			@media only screen and (max-width: 1750px) {
				left: -13px !important;
			}

		}
	}
	ul{
		@include no-format;
		li{
			margin-bottom: 0;
		}
	}
	.flexslider{
		margin:0 auto 70px;
		@media only screen and (max-width: 1050px) {
			margin:0 auto 50px;
		}
		@media only screen and (max-width: 767px) {
			margin:0 auto 20px;
		}
	}
	@media only screen and (max-width: 767px) {
		margin-bottom: 50px;
		width: 100%;
		overflow: hidden;
		.flexslider{
			width: calc(100% - 60px);
		}
		.flex-viewport{
			overflow: visible !important;
			&::before,
			&::after{
				content:'';
				display: block;
				position: absolute;
				top:0;
				bottom:0;
				width: 30px;
				height: 100%;
				background-color:rgba(255,255,255,0.8);
				z-index: 100;
			}
			&::before{
				left: 100%;
			}
			&::after{
				right: 100%;
			}
		}
	}
	header{
		text-align: center;
		margin-bottom: 50px;
		h1,h2,h3,h4,h5,h6{
			color:$green;
			font-family: $abril;
		}
	}
	footer{
		text-align: center;
		@media only screen and (max-width: 767px) {
			a{
				&::before,
				&::after{
					display: none;
				}
				border-radius:$radius;
				box-sizing: border-box;
				min-width: 190px;
				padding:8px 10px;
				line-height: 1.8;
				font-size: 18px;
				font-weight: bold;
				border-width:1px;
				border-style: solid;
				border-color:$green !important;
				color:$green !important;
				&:hover{
					background-color:$green !important;
					color:$white !important;
				}
			}
		}
	}
	.slides{
		li{
			margin-left: 0 !important;
			a:not(.button){
				text-decoration: none;
				color:$lgreen;
				&:hover{
					color:$text;
				}
			}
			figure{
				margin-bottom: 10px;
			}
			h2{
				font-size: 28px;
				line-height: 43px;
				font-family: $abril;
				color:inherit;
				text-align: center;
				@media only screen and (max-width: 767px) {
					text-align:center;
					font-size: 24px;
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {

:root body .page-wrapper main#maincontent.page-main div.columns div.column div  div.carousel--products--blog{
		.flexslider{
		}
		.flex-direction-nav li {
			a{
				background-color:$white !important;
				border-radius:999px !important;
				width: 26px !important;
				height: 26px !important;
				background-size:12px 9px !important;
				border:1px solid $border !important;
				display: block !important;
				box-sizing:border-box !important;
			}
			&.flex-nav-next a{
				right: -13px !important;
			}
			&.flex-nav-prev a{
				left: -13px !important;
			}
		}
	}
}
@media only screen and (min-width: 768px) {
	.post-view--thinner-page header{
		h1,h2,h3,h4,h5,h6{
			font-size: 28px;
		}
	}
}