
form{
	.field{
		max-width:100% !important;
	}
	&.password{
		max-width: 1024px;
		margin-left: auto;
		margin-right: auto;
	}
	legend,
	legend *{
		font-size: 24px;
		font-weight: bold;
		@media only screen and (max-width: 767px) {
			font-size: 18px;
		}
	}
	div.mage-error{
		display: none !important;
	}
	input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
	select{
		line-height: 50px;
		width: 100%;
		display: block;
		box-sizing: border-box;
		padding:0 15px;
		font-size: 16px;
		border:none;
		box-shadow:none;
		appearance:none;
		height: 50px;
		@include transition(0.2s);
		border-radius:$radius;
		@include transition(0.2s);
		background-position: right 15px center;
		&.mage-error{
			background-color:salmon;
		}
		&:focus{
			&:placeholder,
			&::placeholder{
				opacity:0;
			}
		}
	}
	textarea{
		line-height: 1.8;
		width: 100%;
		display: block;
		box-sizing: border-box;
		padding:10px 15px;
		font-size: 16px;
		border:none;
		box-shadow:none;
		appearance:none;
		height: auto;
		@include transition(0.2s);
		border-radius:$radius;
		border:1px solid $border;
		@include transition(0.2s);
		background-position: right 15px center;
	}
	.choice{
		display: block;
		position: relative;
		cursor: pointer;
		li{
			position:relative;
		}
		label{
			display: block !important;
		}
	}
	input[type="checkbox"],
	input[type="radio"]{
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		display: block;
		width: 100%;
		height: 100%;
		z-index:2;
		opacity:0;
		& + label{
			display: block;
			position:relative;
			padding-left:45px;
			width: 100%;
			text-align: left;
			z-index: 1;
			box-sizing:border-box;;
			&::before{
				content:'';
				display: block;
				position:absolute;
				top:0;
				left: 1px;
				border:1px solid $border;
				border-radius:$radius;
				width: 26px;
				height: 26px;
				box-sizing:border-box;
				background-size:15px 15px;
				background-repeat:no-repeat;
				background-position: center center;
			}
		}
		&:checked + label{
			&::before{
				background-image:url(../images/input/crossed.svg);
			}
		}
	}
}
.block-reorder,
.block-compare{
	display: none !important
}
.limiter{
	text-align: left !important;
	*{
		@include inline;
		vertical-align: middle;
		width: auto !important;
	}
		span{
			width: auto !important;
		}
}

table{
	a{
		color:$green !important;
		&:hover{
			color:$dgreen !important;
		}
	}
	*{
		font-size: 16px !important;
		line-height: 1.2 !important;
	}
}