
.authentication-wrapper{
	display: none;
}
.page-header__nav,
.page-header__cta,
.page-footer__top{
	display: none;
}
.page-header{
	border:none !important;
	background-color: $dgreen
}
.page-header__logo{
	background-size:contain;
	background-repeat: no-repeat;
	background-position: center center;
	background-image:url(../images/logocheckout.png);
	clear: both;
	img{
		opacity:0;
	}
}
.crosssell{
	clear: both;
	padding:0 !important;
}
.payment-option-content{
	padding-left:0 !important;
}
.discount-code,
.cart-discount{
	float: none !important;
	width: 100% !important;
	padding-right:0 !important;
	margin-left: auto !important;
	margin-right: auto !important;
	.discount-coupon-form,
	.block.discount{
		width: 100% !important;
	}
	.fieldset{
		display: block;
		width: 100%;
		text-align: left;
	}
	&::before{
		content:'PROMO CODE';
		display: block;
		width: 100%;
		color:$text;
		font-size: 18px;
		line-height: 35px;
		padding-bottom:5px;
		border-bottom:1px solid $border;
		font-weight:600px;
		margin-bottom:20px;
		text-align: left !important
	}
	.payment-option-title,
	.payment-option-title,
	.title{
		display: none !important;
	}
	.payment-option-content,
	.content{
		display: block !important;
		padding:0 !important;
	}
	.payment-option-inner,
	.actions-toolbar,
	.field:not(.payment-option-title){
	    display: inline-block !important;
	    zoom: 1 !important;
	    *display: inline !important;
		width: auto;
		vertical-align: bottom !important;
		margin-bottom: 0 !important;
		width: 100% ;
		margin-bottom: 20px;
		@media only screen and (min-width: 1051px) {
			margin-bottom: 0 !important;
			margin-top: 0 !important;
			input,
			button{
				margin:0 !important;
			}
		}
		@media only screen and (max-width: 1051px) {
			input{
				margin-bottom: 10px !important
			}
		}
	}
	@media only screen and (min-width: 1051px) {
		.payment-option-inner,
		.field{
			width: 315px !important;
			margin-right:15px
		}
		.actions-toolbar,
		.actions-toolbar{
			max-width: 200px;
			width: calc(100% - 230px);
		}
	}
}
.page-header__top{
	.container{
		position:relative;
		@media only screen and (min-width: 1051px) {
			height: 98px;
			&::after{
				position:absolute;
				top:50%;
				right:0;
				transform:translateY(-50%);
				content:'Secure Checkout';
				background-position: right center;
				background-size:19px 25px;
				background-repeat: no-repeat;
				background-image: url(../images/icons/checkout.svg);
				padding-right:30px;
				font-size: 16px;
				line-height: 25px;
				font-weight: 600;
				color:$white;
			}
		}
	}
}
#maincontent{
	max-width: 1600px;
	width: calc(100% - 20px);
	position: relative;
	margin-left: auto;
	margin-right: auto;

	.opc-block-summary {
		#mp-extra-fee {
			margin-bottom: 45px;
			max-width: 652px;
			margin: 0 auto 45px;
			display: block;
			width: 100%;
			float: none;
			position: static !important;

			.item-options {
				margin-bottom: 15px;
			}
		}
	}
	a{
		color:$green;
		&:hover{
			color:$dgreen;
		}
	}

	.payment-method-content,
	.billing-address-details{
		padding-left:0 !important;
	}
	.actions-toolbar{
		display: block;
		margin-bottom:30px;
		button{
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: 10px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.billing-address-details{
		margin-top: 20px;
	}
	.opc-progress-bar{
		width: 100%;
		position:absolute;
		top:0;
		height: 65px;
		border-bottom:1px solid $border;
		left: 50%;
		transform:translateX(-50%);
		display: block !important;
		li{
			position:relative !important;
			max-width: 48% !important;
		}
	}
	.opc-wrapper{
		width: 49%;
		max-width: 653px;
		padding-top:50px;
		.shipping-address-item{
			display: block;
			width: 100%;
			padding:20px !important;
			box-sizing:border-box;
			margin-bottom: 20px !important;
			border-color: $dgreen;
			&::after{
				background-color:$green;
			}
			button{
				@include inline;
				vertical-align: bottom;
				float: none;
				margin-bottom: 0;
			}
			&.selected-item{
				border-color: $green;
			}
		}
		@media only screen and (min-width: 768px) {
			padding-top:100px;
		}
		@media only screen and (max-width: 767px) {
			width: 100%;
			max-width: 100%;
		}
	}
	@media only screen and (min-width: 768px) {
		.minicart-items{
			overflow: hidden;
		}
	}
	@media only screen and (max-width: 767px) {
		.opc-estimated-wrapper{
			margin:0 -10px !important;
			.minicart-wrapper{
				.action.showcart{
					min-width: 0px !important
				}
				display: block;
				*{
					width: 100%;
					text-align: center;
					display: block;
				}
				.counter.qty,
				.counter.qty *{
					padding:0;
					margin:0;
					background-color:transparent;
					box-shadow:none;
					text-shadow:none;
				}
			}
			.estimated-block{
				display: block;
				*{
					display: block;
					width: 100%;
					text-align: center;
				}
			}
		}
	}
	.title,
	.step-title{
		color:$green;
		font-size: 28px;
		line-height: 35px;
		font-family:$abril;
	}
	.step-title{
		margin-bottom: 40px;
		padding-bottom:0;
		border-bottom:none;
	}

	.cart-summary{
		position:static !important;
		display: block !important;
		float: none !important;
		width: 100% !important;
	}
	#shopping-cart-table{
		a{
			text-decoration: none !important;
		}
		.product-item-details *,
		.product-item-details{
			color:$green !important;
			&:hover{
				color:$lgreen !important;
			}
		}
	}
	.checkout-payment-method {
		.ccard {
			& > .field.cvv {
				& > .control {
					padding-right: 40px !important;
				}
			}
		}
	}
}

