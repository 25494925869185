.blog-single__social{
	max-width: 1060px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	ul{
		@include no-format;
		position:absolute;
		right: calc(100% + 55px);
		top: 0;
		padding:20px;
		display: block;
		border: 1px solid $bordero;
		@media only screen and (max-width: 1380px) {
			right: 100%;
		}
		@media only screen and (max-width: 1280px) {
			padding-left: 0;
			padding-top:0;
			
			position:static;
			top: auto;
			right: auto;
		}
		li{
			font-size: 0;
			line-height: 0;
			margin-bottom: 30px;
			&:last-child{
				margin-bottom: 0;
			}
		@media only screen and (max-width: 1280px) {
			margin-bottom: 0;
			@include inline;
			vertical-align: middle;
			margin-right: 20px;
			&:last-child{
				margin-right: 0;
			}
		}
		}
		a{
			display: block;
			width: 40px;
			height: 40px;
			position:relative;
			text-decoration: none !important;
			&::before{
				font-family: 'FontAwesome';
				content: '';
				font-size: 35px;
				line-height: 1;
				position:absolute;
				top:50%;
				left: 50%;
				transform:translate(-50%,-50%);
				display: block;
				width: 100%;
				text-align: center;
				@include transition(0.2s);
				@media only screen and (max-width: 1280px) {
					left: 0;
					transform:translateY(-50%);
				}
			}
			&:hover::before{
				color:$green !important;
			}
		}
	}
	.blog-single__item{
		&--facebook a::before{
			content:'\f09a';
			color:#3b5998;
		}
		&--twitter a::before{
			content:'\f099';
			color:#38A1F3;
		}
		&--pinterest a::before{
			content:'\f231';
			color:#c8232c;
		}
	}
}