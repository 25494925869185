:root body .page-wrapper .breadcrumbs{
	max-width: 1600px;
	padding-left:0;
	padding-right:0;
	margin-top: 35px;
	margin-bottom: 40px;
	width: calc(100% - 20px);
	display: block !important;
	margin-left: auto !important;
	margin-right: auto !important;
	*{
		line-height: 1.8;
		vertical-align: middle;
		font-size: 12px;
		
	}
	a{
		color:$border;
		&:hover{
			color:$green;
		}
	}
	li:not(:last-child){
		&::after{
			line-height: 1.8;
			vertical-align: middle;
			font-size: 1em;
			content:'/';
			margin:0 5px;
		}
	}
	strong{
		color:$lgreen;
	}
}