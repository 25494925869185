.product-wrap--secondary__accordions{
	margin-top: 80px;
	.accordion{
		border-top:none !important;
	}
	h3:not(:first-child){
		margin-top: 15px;
	}
	h3.accordion-title{
		background-color: #eeeeee;
		border:none !important;
		padding:15px 30px;
		box-sizing:border-box;
		font-weight: 400;
		font-size: 24px;
		&::before,
		&::after{
			right: 30px;
		}
		& + div{
			border:none !important;
		}
	}
	h3:not(.accordion-title) {
		padding:0;
		font-weight: 700;
		font-size: 24px;
	}
	.accordion__content{
		background-color: #eeeeee;
		padding:15px 30px 30px;
	}
}