:root body.page-products .page-wrapper{
	   // Product Item
	   @import "product-item";
	.columns,
	.page-title-wrapper{
		width: calc(100% - 20px);
		margin-left:auto;
		margin-right: auto;
		max-width: 1600px;
	}
	.columns{
		padding-top:0 !important;
	}
   	@import "sidebar";
   	.columns{
		@media only screen and (min-width: 768px) {
		   	.sidebar{
		   		width: 300px;
		   		padding-right: 0;
		   	}
		   	.column.main{
		   		width: calc(100% - 320px);
		   	}
	   	}
		@media only screen and (min-width: 1024px) {
		   	.sidebar{
		   		width: 370px;
		   	}
		   	.column.main{
		   		width: calc(100% - 390px);
		   	}
	   	}
	}
}

:root body.catalog-product-view {
	.sizes-finance__finance {
		padding: 15px;
		border: 2px solid $blue;
		max-width: 425px !important;

		&.has--options {
			margin-top: 30px;
		}

		.finance-banners__text {
			@media only screen and (max-width: 1050px) {
				width: calc(100% - 170px);
			}

			@media only screen and (max-width: 395px) {
				width: 100%;
			}
		}

		h2 {
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
		}

		p {
			font-size: 16px;
			margin-top: 5px;
			line-height: 20px;
		}

		img {
			float: right;

			@media only screen and (max-width: 395px) {
				float: none;
				display: block;
				margin: 0 auto !important;
			}
		}
	}
}

:root body.page-products.page-layout-1column .page-wrapper {
	.columns{
		@media only screen and (min-width: 768px) {
			.column.main{
				width: 100%;
				padding-top: 0;

				.block.filter,
				.trustpilot-widget {
					display: none;
				}

				.promos {
					width: 100%;
				}
			}
		}
		@media only screen and (min-width: 1024px) {
			.column.main{
				width: 100%;
				padding-top: 0;

				.block.filter,
				.trustpilot-widget {
					display: none;
				}

				.promos {
					width: 100%;
				}
			}
		}
	}
}

:root body.catalogsearch-result-index {
	.breadcrumbs{
		margin-bottom: 20px !important;
	}
	.page-title-wrapper{
		margin-bottom: 50px;
	}
}

.toolbar-products{
	&:first-child{
		padding-bottom:30px;
		margin-bottom: 30px;
		border-bottom:1px solid $bordero;
	}
	.toolbar-amount{
		margin-bottom: 0;
		line-height: 43px;
		font-size: 24px;
		font-family:$abril;
		color:$dgreen;
		padding:0;
		@media only screen and (max-width: 1060px) {
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: 20px;
		}
	}
	.modes{
		display: block;
		float: right;
		border:1px solid $border;
		overflow: hidden;
		border-radius:$radius;
		font-size: 0;
		line-height: 0;
		margin-right: 0px;
		@media only screen and (max-width: 1060px) {
			display: block;
			width: 49%;
			float: left;
		}
		@media only screen and (max-width: 500px) {
			float: none;
			display: none;
		}
		.modes-label{
			display: none !important;
		}
		.modes-mode{
			border-radius:0px !important;
			@include transition(0.2s);
			appearance:none !important;
			box-shadow:none !important;
			background-color:transparent !important;
			border:none !important;
			padding:8px 15px !important;
			@include inline;
			text-decoration: none;
			@media only screen and (max-width: 1060px) {
				box-sizing: border-box;
				width: 50%;
			}
			*{
				text-decoration: none;
			}
			span{
				@include inline;
				vertical-align: middle !important;
				font-size: 12px !important;
				line-height: 24px !important;
				margin:0 !important;
			    width: auto !important;
			    height: auto !important;
			    position: static !important;
			    clip: initial !important;
			    color:$dgreen !important;
			}
			&::before{
				display: none !important;
			}
			&::after{
				content:'' !important;
				@include inline;
				vertical-align: middle !important;
				margin-left: 15px !important;
				width: 26px !important;
				height: 26px !important;
				background-repeat:no-repeat !important;
				background-position: center center !important;
			}
			&.mode-grid::after{
				background-size: 24px 24px !important;
				background-image:url(../images/icons/grid-view.svg) !important;
			}
			&.mode-list::after{
				background-size: 26px 24px !important;
				background-image:url(../images/icons/list-view.svg) !important;
			}
			&.active{
				background-color: #EEEEEE !important;
			}
			&:not(.active):hover{
				background-color:$lgreen !important;
			}
		}
	}
	.sorter{
		right: 215px !important;
		position: absolute !important;
		@media only screen and (max-width: 1060px) {
			display: block;
			width: 49%;
			position:static !important;
			right: auto !important;
			float: right;
		}
		@media only screen and (max-width: 500px) {
			float: none;
			width: 100%;
			margin-bottom: 20px;
		}
		label{
			font-size: 16px;
			@media only screen and (max-width: 1060px) {
				display: none !important;
			}
		}
		select{
			margin-left:15px;
			margin-right: 15px;
			height: 43px;
			border:1px solid $border;
			background-color: transparent;
			border-radius: $radius;
			line-height: 41px;
			box-sizing: border-box;;
			padding: 0 45px 0 15px;
			font-size: 16px;
			font-weight: 400;
			font-family:$proxima;
			width: 192px;
			background-position: right 15px center;
			background-repeat: no-repeat;
			background-size: 13px 7px;
			background-image:url(../images/icons/chevron-down.svg);
			appearance:none;
			box-shadow:none;
			@media only screen and (max-width: 1060px) {
				width: 100%;
				box-sizing:border-box;
				margin:0 !important;
			}
		}
	}
}
