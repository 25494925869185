/* base styles: opinionated defaults */

@media only screen and (max-width: 959px) {
	
}

@media only screen and (max-width: 767px) {
	
}

@media only screen and (max-width: 479px) {
	
}