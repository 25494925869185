.fotorama{
	&--fullscreen{
		z-index: 999999999999 !important;
	}
	&__stage{
		border:1px solid $bordero;
		box-sizing:border-box;
	    cursor: -moz-zoom-in; 
	    cursor: -webkit-zoom-in; 
	    cursor: zoom-in;
	    margin-bottom: 10px;
	    &::after{
	    	content:'';
	    	display: block;
	    	position:absolute;
	    	bottom:24px;
	    	right: 22px;
	    	width: 21px;
	    	height: 21px;
	    	background-size:contain;
	    	background-repeat:no-repeat;
	    	background-position:center center;
	    	background-image:url(../images/fotorama/mag.svg);
	    	@include transition(0.2s);
	    }
	    &:hover::after{
	    	opacity:0.5;
	    }
	}
	&__thumb__arr{
		background-color:rgba(255,255,255,0.8);
		div{
			width:7px;
			height: 13px;
			top:50%;
			background-size:contain;
			background-repeat: no-repeat;
			background-position: center center;
			@include transition(0.2s);
		}	
		&--left{
			div{
				transform:translateY(-50%);
				background-image:url(../images/icons/chevron-left-large.svg);
			}
			&:hover{
				div{
					left: 0;
				}
			}
		}
		&--right{
			div{
				transform:translateY(-50%);
				background-image:url(../images/icons/chevron-right-large.svg);
			}
			&:hover{
				div{
					right: 0;
				}
			}
		}
	}
	&__nav {
		display: none;
		height: 0 !important;

		&__frame .fotorama__thumb {
			background-color: transparent;

			img {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				box-sizing: border-box;
				padding: 0 5px;
			}
		}
	}
	&__arr{
		opacity:1;
		transform:none !important;
		background-color: transparent !important;
		div{
			width:13px;
			height: 25px;
			top:50%;
			background-size:contain;
			background-repeat: no-repeat;
			background-position: center center;
			@include transition(0.2s);
		}		
		&--next {
			div{
				right: 20px;
				left: auto;
				transform:translateY(-50%);
				background-image:url(../images/icons/chevron-right-large.svg);
			}
			&:hover{
				div{
					right: 0;
				}
			}
		}
		&--prev {
			div{
				right: auto;
				left: 20px;
				transform:translateY(-50%);
				background-image:url(../images/icons/chevron-left-large.svg);
			}
			&:hover{
				div{
					left: 0;
				}
			}
		}
	}
}