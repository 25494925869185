
:root body .page-wrapper .product-item-actions > div{
	margin-bottom: 10px;
	&:last-child{
		margin-bottom: 0;
	}
}
:root body .page-wrapper button.action:not(.search):not(.close),
:root body .page-wrapper .gform_footer input,
:root body .page-wrapper .product-item-actions .action{
	&::before,
	&::after{
		display: none;
	}
	background-color: $green;
	color:$white;
	border:none;
	@include transition(0.2s);
	font-size: 16px !important;
	line-height: 28px !important;
	font-weight: bold;
	font-family: $proxima;
	color:$white;
	min-width: 160px;
	padding:10px 20px !important;
	text-align: center;
	@include inline;
	vertical-align: middle;
	border-radius:$radius;
	box-sizing:border-box;
	border:1px solid $green;
	box-shadow:none;
	text-decoration: none;
	*{
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		color:inherit;
		width: auto;
		height: auto;
		clip: none;
		position:static;
		overflow: visible;
		text-transform: none;
		text-decoration: none;
	}
	&:hover{
		color:$green;
		background-color:$white;
	}
}
:root body .page-wrapper .product-item-actions a{
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0;
	margin-bottom: 10px;
	&:last-child{
		margin-bottom: 0;
	}
}
.button{
	text-decoration: none;
	text-align: center;
	@include inline;
	vertical-align: middle;
	*{
		text-decoration: none;
	}
	&--arrow{
		@include inline;
		vertical-align: middle;
		max-width: 335px;
		font-size: 16px;
		line-height: 1.8;
		color:$white;
		text-decoration: none;
		&:hover{
			color:$dgreen;
		}
		&::after{
			content: '';
			@include inline;
			width: 13px;
			margin-left: 10px;
			height: 10px;
			background-size: 13px 10px;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url(../images/icons/arrow-right.svg);
		}

		&.button--white{
			font-size:16px;
			&::after{
				margin-left: 15px;
			}
		}
		&.button--green{
			color:$green;
			font-size: 16px;
			&::after{
				margin-left: 25px;
				width: 19px;
				height: 11px;
				background-size: 19px 11px;
				background-image: url(../images/icons/arrow-right-green.svg);
			}
		}
	}

	&--outline{
		border-radius:$radius;
		box-sizing: border-box;
		min-width: 190px;
		padding:9px 10px;
		line-height: 1.8;
		font-size: 18px;
		font-weight: bold;
		border-width:1px;
		border-style: solid;
		&.button--green{
			border-color:$green !important;
			color:$green !important;
			&:hover{
				background-color:$green !important;
				color:$white !important;
			}
		}
		&.button--white{
			border-color:$white !important;
			color:$white !important;
			&:hover{
				background-color:$white !important;
				color:$text !important;
			}
		}
	}
	&--fill{
		border-radius:$radius;
		box-sizing: border-box;
		min-width: 190px;
		padding:9px 10px;
		line-height: 1.8;
		font-size: 18px;
		font-weight: bold;
		border-width:1px;
		border-style: solid;
		height: 50px;
		&.button--green{
			border-color:$green !important;
			background-color:$green !important;
			color:$white !important;
			&:hover{
				background-color:$white !important;
				color:$green !important;
			}
		}
		&.button--white{
			border-color:$white !important;
			background-color:$white !important;
			color:$green !important;
			&:hover{
				background-color:$green !important;
				color:$white !important;
			}
		}
	}
}