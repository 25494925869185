.product-wrap .container{
	@include clearfix;
	.product.media,
	.product-info-main{
		width: 100%;
			@media only screen and (min-width: 1200px) {
				width: 50%;
			}
	}
	.product.media{
		margin-bottom: 50px;
		@media only screen and (min-width: 1200px) {
			box-sizing:border-box;
			padding-right: 6.25%;
		}
		@import 'fotorama';
	}
	.page-title-wrapper.product{
		font-size: 24px !important;
		line-height: 43px;
		font-family: $abril;
		font-weight: 400;
		color:$dgreen;
		margin-bottom: 15px;
		*{
			margin-bottom:0;
			text-decoration: none;
			font-size: inherit !important;
			line-height: inherit !important;
			font-family: inherit !important;
			font-weight: inherit !important;
			color:inherit !important;
			@media only screen and (min-width: 1200px) {
				overflow: hidden;
				display: block;
				width: 100%;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
	.product-info-price{
		font-family: $abril;
		width: 100% !important;
		margin-left: auto !important;
		margin-right: auto !important;
		border:none !important;
		@media only screen and (max-width: 767px) {
		width: 100% !important;
		margin-left: auto !important;
		margin-right: auto !important;
			*{
				text-align: center;
			}
		}
		.special-price,
		.normal-price{
			font-size: 36px;
			line-height: 48px;
			font-weight: bold;
			display: block;
			*{
				font-size: inherit !important;
				line-height: inherit !important;
				font-weight: inherit !important;
			}
		}
		.old-price{
			font-size: 16px;
			line-height: 1.2;
			text-decoration: line-through;
			font-weight: 400;
			display: inline-block;
			vertical-align: middle;
			color:#727272;
			*{
				color: inherit !important;
				font-size: inherit !important;
				line-height: inherit !important;
				font-weight: inherit !important;
			}
			&::before{
				content:'Was ';
			}
		}
		.save-price{
			@include inline;
			vertical-align: bottom;
			color:#D0021B;;
			font-size: 16px;
			line-height: 1.2;
		}
		.price-label{
			display: none !important
		}
	}
	.product-info-main{
		&__share{
			text-align: center;
			margin-top: 50px;
			h6{
				margin:0 12px;
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 0 !important;
			}
			ul{
				@include no-format;
				li{
					margin-bottom: 0;
					margin:0 12px;
					a{
						font-size: 0;
						line-height: 0;
						display: block;
						color:transparent;
						width: 25px;
						height: 25px;
						position:relative;
						&:hover{
							opacity: 0.5;
						}
						&::before{
							content:'';
							display: block;
							position:absolute;
							display: block;
							width: 100%;
							height: 100%;
							background-position: center center;
							background-repeat: no-repeat;
						}
					}
					&.email a{
						width: 26px;
						&::before{
							background-image:url(../images/product/email.svg);
							background-size:26px 20px;
						}
					}
					&.facebook a{
						width: 12px;
						&::before{
							background-image:url(../images/product/facebook.svg);
							background-size:12px 20px;
						}
					}
					&.pinterest a{
						width: 16px;
						&::before{
							background-image:url(../images/product/pinterest.svg);
							background-size:16px 20px;
						}
					}
					&.twitter a{
						width: 25px;
						&::before{
							background-image:url(../images/product/twitter.svg);
							background-size:25px 20px;
						}
					}
				}
				
			}
			*{
				@include inline;
				vertical-align: middle;
				text-decoration: none;
			}
		}
		.overview{
			margin-top:0;
			margin-bottom: 40px;
			p{
				display: block;
				width: 100%;
			}
			a{
				color:$green;
				&:hover{
					color:$dgreen;
				}
			}
			p{
				font-size: 16px;
				line-height: 29px;
			}
		}
		.product-notices{
			& > div{
				margin-bottom: 10px;
			}
			&__banner{
				border-width:1px;
				border-style:solid;
				box-sizing:border-box;
				width: 100%;
				padding:12px 20px;
				min-height: 65px;
				display: block;
				position:relative;
				border-radius: 5px;
				@media only screen and (max-width: 767px) {
					min-height: 0 !important;
					padding:30px 5% !important;
				}
				p{
					padding:12px 20px;
					position:absolute;
					top:50%;
					left: 0;
					right:0;
					transform: translateY(-50%);
					box-sizing:border-box;;
					max-width:100%;
					@media only screen and (max-width: 767px) {
						position:static !important;
						display: block;
						width: 100%;
						transform:none;
						margin-bottom: 20px;
						padding:0 !important;
						text-align: center !important;
						font-size: 18px !important;
						*{
							font-size: 18px !important;
						}
						&:last-child{
							margin-bottom: 0;
						}
						i{
							@include inline;
							position:static !important;
							display: block !important;
							width: 100% !important;
							text-align: center;
							transform: none !important;
							margin-bottom: 10px !important
						}
					}
				}
				*:last-child{
					margin-bottom: 0;
					font-size: 16px;
				}
				a{
					color:$green;
				}
				&--delivery{
					border-color:$green;
					border-width:2px;
					color:$green;
					font-size: 17px !important;
					*{
						color:inherit;
						font-size: inherit;
					}
				}
				&--offer{
					background-color:$green;
					border-color:$green;
					color:$white;
					font-size: 17px !important;
					*{
						color:inherit;
						font-size: inherit;
					}
				}
				&--notice {
					border-color:#244856;
					p{
						display: block;
						width: 100%;
						margin-bottom: 0;
						padding-left:75px;
						color:#244856;
					}
				}
				.notice__icon{
					width: 40px;
					height: 40px;
					background-position: center center;
					background-repeat: no-repeat;
					display: block;
					position:absolute;
					left: 20px;
					top:50%;
					transform:translateY(-50%);
					@media only screen and (max-width: 767px) {
						position:static !important;
						display: block !important;
						width: 100% !important;
						text-align: center;
						transform: none !important;
						margin-bottom: 10px !important
					}
					&--one{
						background-image:url(../images/product/care.svg);
						background-size: 35px 40px;
					}
					&--two{
						background-image:url(../images/product/environment.svg);
						background-size: 30px 40px;
					}
				}
			}
		}
		.sizes-finance{
			@include clearfix;
			margin-top: 30px;
			margin-bottom: 35px;
			&__sizes{
				box-sizing:border-box;
				@include clearfix;
				float:left;
				max-width: calc(100% - 450px);
				@media only screen and (max-width: 767px) {
					max-width: 100%;
					float: none;
					margin-bottom: 35px !important;
				}
				dl{}
				dt,
				dd{
					display: block;
					float: left;
					margin-bottom: 1px;
					padding:8px 12px;
					box-sizing:border-box;
					font-size: 16px;
					line-height: 20px;
				}
				dt{
					width: 75px;
					background-color:#f0f0f0;
					color:$dgreen;
					font-weight: 600;
				}
				dd{
					width: calc(100% - 75px);
					font-weight: 400;
					color:$text;
				}
			}
			&__finance{
				display: block;
				float: right;
				max-width: 380px;
				@media only screen and (max-width: 767px) {
					max-width: 100%;
					float: none;
				}
				img{
					max-width: 100%;
					height: auto;
					margin:0 auto 0 0;
					display: block;
				}
			}
		}
		.product-reviews-summary{
			float: none;
			width: 100%;
			padding-bottom:30px;
			border-bottom:1px solid rgba(187, 187, 187, .3);
			margin-bottom: 25px;
		}
		.product-social-links{
			border-bottom:1px solid rgba(187, 187, 187, .3);
			padding-bottom: 25px;
			margin-bottom: 25px;
			a{
				width: 50px;
				height: 50px;
				display: block;
				position:relative;
				&::before,
				&::after{
					content:'';
					display: block;
					font-size: 0;
					line-height: 0;
					font-family: none;
					background-size:26px 23px;
					background-repeat:no-repeat;
					background-position: center center;
					@include inline;
					vertical-align: middle;
					opacity:1;
					width: 26px;
					height: 23px;
					position:absolute;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);
					@include transition(0.2s);
				}
				&::before{
					z-index: 2;
					background-image:url(../images/icons/product-favourite-on.svg);
					margin-top: 1px;
					opacity:0;
				}
				&::after{
					z-index: 1;
					background-image:url(../images/icons/product-favourite-off.svg);
					
				}
				&:hover::after{
					opacity:0;
				}
				&:hover::before{
					opacity:1;
				}
			}
			span{
				display: block;
				white-space: nowrap;
				position:absolute;
				left: 100%;
				max-width: calc(100vw - 70px);
				top:50%;
				transform:translateY(-50%);
			}
		}
		form{
			.multiselect{
				padding-top:10px;
				padding-bottom:10px;
				height: auto !important;
			}
			.box-tocart{
				position:relative;
				border-bottom:1px solid rgba(187, 187, 187, .3);
				padding-bottom: 25px;
				margin-bottom: 25px;
				.field,
				.actions{
					@include inline;
					vertical-align: middle;
					margin-bottom: 0 !important;
				}
				.field{
					width: 80px;
					margin-right: 10px;
				}
				.actions{
					width: calc(100% - 90px);
					button{
					margin-bottom: 0 !important
					}
				}
				.field{
					position:relative;
					.button-functions{
						position:absolute;
						top:0;
						bottom:0;
						right: 0;
						display: block;
						width: 30px;
						background-position:center center;
						background-repeat:no-repeat;
						background-size:9px 13px;
						background-image:url(../images/icons/number-spinner.svg);
						cursor:pointer;
						*{		
							cursor:pointer;
						}
						li{
							margin-bottom: 0;
							display: block;
							position:relative;
							height: 25px;
							span{
								position:absolute;
								top:50%;
								left: 50%;
								transform:translate(-50%,-50%);
								width: 100%;
								height: 100%;
							}
							i{
								font-style: normal;
								opacity:0;
								display: block;
								position:absolute;
								top:0;
								left: 0;
								right: 0;
								bottom: 0;
								width: 100%;
								height: 100%;
								text-align:center;
								cursor:pointer;
							}
						}
					}
				}
			}
			.product-options-bottom{
				.actions,
				.field{
					@include inline;
					vertical-align: middle;
					margin-bottom: 0;
					input,
					button{
						margin-bottom:0;
					}
					button{
						min-width: 240px;
					}
					input{
					}
					label{
						display: none;
					}
				}

			}
			.field{
				position:relative;
			}
			.field label {
				display: block;
				padding-left: 0;
			}
			.field.qty label {
				display: none;
			}
			input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="number"]),
			select{
				line-height: 48px;
				width: 100%;
				display: block;
				box-sizing: border-box;
				padding:0 15px;
				font-size: 16px;
				border:none;
				box-shadow:none;
				appearance:none;
				height: 50px;
				@include transition(0.2s);
				border-radius:$radius;
				border:1px solid $border;
				@include transition(0.2s);
				background-position: right 15px center;
				&.mage-error{
					background-color:salmon;
				}
				&:focus{
					&:placeholder,
					&::placeholder{
						opacity:0;
					}
				}
			}
			input[type="number"]{
				line-height: 48px;
				width: 100%;
				max-width: 80px;
				margin-right: 10px;
				display: block;
				box-sizing: border-box;
				padding:0 15px;
				font-size: 16px;
				border:none;
				box-shadow:none;
				appearance:none;
				height: 50px;
				@include transition(0.2s);
				border-radius:$radius;
				border:1px solid $border;
				@include transition(0.2s);
				background-position: right 15px center;
				text-align: left;
				&.mage-error{
					background-color:salmon;
				}
				&:focus{
					&:placeholder,
					&::placeholder{
						opacity:0;
					}
				}
			}
			textarea{
				line-height: 1.8;
				width: 100%;
				display: block;
				box-sizing: border-box;
				padding:10px 15px;
				font-size: 16px;
				border:none;
				box-shadow:none;
				appearance:none;
				height: auto;
				@include transition(0.2s);
				border-radius:$radius;
				border:1px solid $border;
				@include transition(0.2s);
				background-position: right 15px center;
			}
			.gfield_checkbox,
			.gfield_radio{
				margin-top: 40px;
				max-width: 620px;
				display: block;
				@media only screen and (min-width: 960px) {
					width: calc(100% - 300px);
				}
				li{
					position:relative;
				}
				label{
					display: block !important;
				}
			}
			input[type="checkbox"],
			input[type="radio"]{
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				display: block;
				width: 100%;
				height: 100%;
				z-index:2;
				opacity:0;
				cursor:pointer;
				& + label{
					display: block;
					position:relative;
					padding-left:45px;
					width: 100%;
					text-align: left;
					z-index: 1;
					box-sizing:border-box;;
					&::before{
						content:'';
						display: block;
						position:absolute;
						top:0;
						left: 1px;
						border:1px solid $border;
						border-radius:$radius;
						width: 26px;
						height: 26px;
						box-sizing:border-box;
						background-size:15px 15px;
						background-repeat:no-repeat;
						background-position: center center;
					}
				}
				&:checked + label{
					&::before{
						background-image:url(../images/input/crossed.svg);
					}
				}
			}
		}
	}
}

:root body.catalog-product-view{
	.product.data.items{
		margin-left: auto !important;
		margin-right: auto !important;
		width: 100% !important;
		display: none !important;
	}
	&.page-product-configurable .page-wrapper .product-wrap .container{}
	&:not(.page-product-configurable) .page-wrapper .product-wrap .container{
		.product-info-main{
			position:relative;
		}
		.product-info-price{
			.price-box{
				padding-bottom:0 !important;
			}
			@media only screen and (min-width: 767px) {
				.price-box{
					float: left;
					display: block;
					width: calc(100% - 370px);
					border:none !important;
				}
			}
		}
		@media only screen and (min-width: 767px) {
			.product-add-form:not(.has--options){
				position:absolute;
				padding-top:0;
				right: 50px;
				top:150px;
				width: 330px;
				button{
					width: 100%;
				}
			}
			.product-social-links{
				right: 0;
				top:150px;
				position:absolute;
				width: auto;
				span{
					display: block;
					white-space: nowrap;
					position:absolute;
					left: 100%;
					max-width: calc(100vw - 70px);
					top:50%;
					transform:translateY(-50%);
				}
				@media only screen and (min-width: 767px) {
					span{
						display: none;
					}
				}
			}
		}
	}
}