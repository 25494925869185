.page-title-wrapper{
	border-color:$border;
	margin-bottom: 30px;
	*{
		border-color:$border;
	}
	h1{
		font-size: 48px !important;
		@media only screen and (max-width: 767px) {
			font-size: 36px !important;
		}
	}
	h2{
		font-size: 36px;
		@media only screen and (max-width: 767px) {
			font-size: 28px;
		}
	}
	h3{
		font-weight: bold;
		font-size: 36px;
		@media only screen and (max-width: 767px) {
			font-size: 28px;
		}
	}
}

.block-title,
.block-title *{
	font-size: 24px !important;
	font-weight: bold !important;
	color:$dgreen !important;
	border-color:$border;
	a,
	a *{
		font-weight: 400 !important;
		text-decoration: none !important;
		font-size: 0.8em !important;
		color:$green !important;
		@include transition(0.2s);
		cursor:pointer;
		&:hover{
			color:$lgreen !important;
		}
	}
}
.box-title,
.box-title *{
	font-size: 20px !important;
	font-weight: 400 !important
}
.box-content{
	padding-top: 30px;
	border-top:1px solid $border;
}
.box-content,
.box-content *,
.box-actions,
.box-actions *,
.addresses,
.addresses *{
	font-size: 16px;
	a,
	a *{
		color:$green !important;
		@include transition(0.2s);
		cursor:pointer;
		text-decoration: none;
		&:hover{
			color:$lgreen !important;
		}
	}
}