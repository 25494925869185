:root body.checkout-index-index .page-wrapper,
:root body.checkout-onepage-success .page-wrapper,
:root body.checkout-cart-index .page-wrapper{
	overflow: hidden;
	@import 'layout';
	@import 'form';
	@import 'step-indicator';
	@import 'modal';
	.page-title-wrapper{
		margin-bottom: 60px !important;
	}
}
:root body .modals-wrapper{
	@import 'modal';
}
:root body.checkout-cart-index .page-wrapper #maincontent{
	a{
		color:$green;
		&:hover{
			color:$dgreen;
		}
	}
	@media only screen and (min-width: 1051px) {
		.cart-summary{
			float: right !important;
			width: 33.333% !important;
		}
		.form.form-cart{
			width: calc(66.666% - 20px) !important;
			float:left !important;
		}
	}
	.cart-discount,
	.block.crosssell{
		clear: both;
	}
}
:root body.checkout-index-index .page-wrapper{
	#maincontent{
		padding-top:65px !important;
		// @media only screen and (max-width: 767px) {
		// 	padding-top:100px !important;
		// }
	}
}
:root body.checkout-onepage-success .page-wrapper #maincontent{
	.actions-toolbar,
	form{
		text-align: center !important;
		a,
		input[type="submit"]{    
			&::before,
			&::after{
				display: none;
			}
			background-color: $green;
			color:$white;
			border:none;
			@include transition(0.2s);
			font-size: 16px !important;
			line-height: 28px !important;
			font-weight: bold;
			font-family: $proxima;
			color:$white;
			min-width: 160px;
			padding:10px 20px !important;
			text-align: center;
			@include inline;
			vertical-align: middle;
			border-radius:$radius;
			box-sizing:border-box;
			border:1px solid $green;
			box-shadow:none;
			text-decoration: none;
			width: auto !important;
			max-width: 500px !important;
			margin:0 !important;
			*{
				font-size: inherit;
				line-height: inherit;
				font-weight: inherit;
				color:inherit;
				width: auto;
				height: auto;
				clip: none;
				position:static;
				overflow: visible;
				text-transform: none;
				text-decoration: none;
			}
			&:hover{
				color:$green;
				background-color:$white;
			}
		}
	}
}
:root body.checkout-cart-index .page-wrapper #maincontent,
:root body.checkout-onepage-success .page-wrapper #maincontent{
	.page-title-wrapper,
	.cart-empty,
	.column.main{
		text-align: center;
		max-width: 1600px;
		margin-left: auto;
		margin-right:auto;
		width: calc(100% - 20px);
	}
	.page-title-wrapper{
		margin-top:20px;

	}
	// .cart-summary{
	// 	max-width: 
	// }
}