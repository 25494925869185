:root body .page-wrapper .post-view--thinner-page{
	.accordion,
	.promos_overlay,
	.gallery,
	.banner,
	.full-width-image{
		max-width: 1060px;
	}
}

:root body .page-wrapper .post-view__article {
	max-width:1060px;
	margin-left: auto;
	margin-right: auto;
}