.sidebar{

	@import 'filter';

	&__finance-options{
		overflow: hidden;
		@media only screen and (max-width: 767px) {
			display: none;
		}
		ul{
			@include no-format;
			text-align: center;
			margin-bottom: 50px;
			font-size: 0;
			line-height: 0;
			@media only screen and (max-width: 767px) {
				width: calc(100% + 20px);
				margin-left: -10px;
				margin-right: -10px;
			}
			li{
				width: 100%;
				margin-bottom: 40px;
				@media only screen and (max-width: 767px) {
					width: calc(50% - 20px);
					@include inline;
					vertical-align: top;
					box-sizing:border-box;
					margin:0 10px 40px;
				}
				@media only screen and (max-width: 375px) {
					width: calc(100% - 20px);
				}
				&:last-child{
					margin-bottom: 0;
				}
				img{
					display: block;
					width: 100%;
					height: auto;
				}
			}
		}
	}
}