:root body .page-wrapper{
   // Pagination
   @import "pagination";
   // Filter
   @import "filter";
   .sidebar .block-reorder{
   	display: none !important;
   }
}

:root body.page-products .page-wrapper{
	.main__full-desc{
		padding-top:40px;
		border-top:1px solid #eeeeee;
		text-align: center;
		@media only screen and (max-width: 1051px) {
			margin-bottom: 50px;
		}
		*{
			display: block;
			max-width: 1060px;
			margin-left: auto;
			margin-right: auto;
		}
		@media only screen and (max-width: 767px) {
			padding-top:30px;
		}
	}
	.block-wishlist,
	.block-compare{
		display: none !important;
	}
	.pages{
		text-align: center;
		width: 100%;
		margin-bottom: 30px;
		@media only screen and (max-width: 767px) {
			margin-bottom: 00px;
		}
		.toolbar-amount{
			display: none;
		}
		a,
		span,
		strong{
			margin:0;
			width: 35px;
			height: 35px;
			display: block;
			text-align: center;
			padding:0 !important;
			line-height: 35px;
			border-radius:$radius;
			color:$dgreen;
			text-decoration: none;
			@include transition(0.2s);
			box-shadow: none;
			appearance: none;
			&:hover{
				color:$white;
				background-color:$dgreen;
			}
		}
		li{
			margin:0 8px;
			@include inline;
			vertical-align: middle;
			background-color: transparent !important;
			&.current{
				span{
					color:$white;
					background-color: $dgreen;
					text-align: center;
				}
			}
		}
		.action{
			background-color:transparent;
			border:none;
			width: 35px;
			height: 35px;
			border-radius: $radius;
			background-color:$white !important;
			background-size:8px 14px;
			background-repeat:no-repeat;
			background-position:center center;
			&::before{
				display: none;
			}
			&.next{
				background-image:url(../images/pagination/next.svg);
			}
			&.previous{
				background-image:url(../images/pagination/previous.svg);
			}
		}
	}
}
// layout
@import "layout";

:root body.catalog-product-view .page-wrapper{
	@import 'single-related';
	@import 'single-trustpilot';
	@import 'single-accordions';
	@import 'single-rating';
	@import 'fotorama';
	.product.info.detailed{
		display: none !important;
	}
		.product-wrap--secondary__related,
	.columns,
	.column.main{
		margin-bottom: 0 !important;
		padding-bottom:0 !important;
	}
}

@import 'single-top';
:root body{
	@import 'fotorama';
	&:not(.fotorama__fullscreen){
		.fotorama{
			&__stage{
				height: 400px !important;
				@media only screen and (max-width: 1024px) {
					height: 300px !important;
				}
			}
		}
	}
}
