.post-list-wrapper{
	max-width: 1600px;
	width: calc(100% - 20px);
	margin-left: auto;
	margin-right: auto;
	.post-list{
		@include no-format;
		width: calc(100% + 24px);
		margin-left: -12px;
		margin-right: -12px;
		font-size: 0;
		line-height: 0;
		margin-bottom: 18px;
		.item{
			@include inline;
			vertical-align: top;
			width: calc(25% - 24px);
			margin:0 12px 24px;
			@media only screen and (min-width: 1025px) {
				&.double{
					width: calc(66.6666% - 24px) !important;
				}
			}
			@media only screen and (min-width: 1441px) {
				&.double{
					width: calc(50% - 24px) !important;
				}
			}
			@media only screen and (max-width: 1440px) {
				width: calc(33.3333% - 24px);
			}
			@media only screen and (max-width: 1024px) {
				width: calc(50% - 24px);
			}
			@media only screen and (max-width: 767px) {
				width: calc(100% - 24px);
			}
		}
	}
}