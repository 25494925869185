.banner{
	background-color: $dgreen;
	text-align: center;
	padding:10px 25px;
	box-sizing: border-box;
	margin-bottom: 25px;
	@media only screen and (max-width: 767px) {
		padding-top:40px;
		padding-bottom:40px;
	}
	@media only screen and (max-width: 1050px) {
		margin-bottom: 10px;
	}
	div{
		text-align: center;
		color:$white;
		@include inline;
		vertical-align: middle;
		margin-right: 240px;
		p{
			font-size: 36px;
			line-height: 1.8;
			font-family: $abril;
			@media only screen and (max-width: 1450px) {
				font-size: 28px;
			}
			@media only screen and (max-width: 1050px) {
				font-size: 20px;
			}
			@media only screen and (max-width: 767px) {
				font-size: 28px;
			}
		}
		span{
			font-size: 1.3em;
			line-height: 1.8;
			font-weight:bold;
			margin-right: 0.5em;
			@media only screen and (max-width: 1050px) and (min-width: 768px) {
				font-size: 32px;
			}
			@media only screen and (max-width: 767px) {
				font-size: 48px;
				line-height: 43px;
				margin-bottom: 10px;
			}
		}
			@media only screen and (max-width: 1450px) {
				margin-right: 50px;
			}
		@media only screen and (max-width: 1050px) {
			margin-right: 20px;
		}
		@media only screen and (max-width: 767px) {
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: 20px;
			span{
				display: block;
				width: 100%;
				text-align: center;
			}
		}
	}
	&.container--thinner_banner{
		max-width: 1230px;
		margin:40px auto 60px;
		& > div{
			margin-right: 60px;
		}
	}
	a{
		@include inline;
		vertical-align: middle;
		margin-top: 10px;
	}
}
