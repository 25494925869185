.wysiwyg .container{
	margin-bottom: 60px;
	@media only screen and (max-width: 767px) {
		margin-bottom: 70px;
	}

	h1,h2,h3,h4,h5,h6{
		color:$green;
		font-family: $abril;
	}

	@media only screen and (max-width: 767px) {
		li, p{
			font-size: 16px
		}
	}
	*:not(.button):not(strong) {
		display: block;
		width: 100%;
		max-width: 1060px;
		margin-left: auto;
		margin-right: auto;
	}
}