/*Fonts here*/

@font-face {
  font-family: 'FontAwesome';
  src: url('fonts/fontawesome-webfont.eot');
  src: url('fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/fontawesome-webfont.woff2') format('woff2'),
    url('fonts/fontawesome-webfont.woff') format('woff'),
    url('fonts/fontawesome-webfont.ttf') format('truetype'),
    url('fonts//fontawesome-webfont.svg#fontawesomeregular') format('svg');
//  src: url('fonts/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}