.category-image,
.hero{
	position:relative;
	margin:0 auto 70px !important;
	border:none !important;
	margin-bottom: 70px !important;
	@media only screen and (max-width: 1050px) {
		margin:0 auto 40px !important;
	margin-bottom: 40px !important;
	}
	@media only screen and (max-width: 1050px) {
		margin: 0 auto 10px !important;
	margin-bottom: 10px !important;
	}
	.container{
		width:calc(100% - 240px);
		@media only screen and (max-width: 1050px) {
			width:calc(100% - 160px);
		}
		@media only screen and (max-width: 767px) {
			width: 100%;
		}
	}
	.flex-direction-nav li{
		a{
			position:absolute;
			z-index: 100;
			top:50%;
			transform:translateY(-50%);
			width:28px;
			height: 21px;
			background--size:28px 21px;
			background-repeat: no-repeat;
			background-position: center center;
			background-repeat: no-repeat;
			background-color:transparent;
			border:none !important;
			font-size: 0;
			line-height: 0;
			text-indent: -9999px;
			overflow: hidden;
			box-shadow:none !important;
			appearance:none !important;
			opacity:1 !important;
			@media only screen and (max-width: 767px) {
				background-color:$white !important;
				border-radius:999px;
				width: 26px;
				height: 26px;
				top:260px;
				background-size:12px 9px;
			}
			&::before,
			&::after{
				display: none;
			}
		}
		&.flex-nav-next a{
			background-image: url(../images/hero/desktop/right.svg);
			right: 70px !important;
			@media only screen and (max-width: 1050px) {
				right: 30px !important;
			}
			@media only screen and (max-width: 767px) {
				right: 6px !important;
				background-image: url(../images/hero/mobile/right.svg)
			}

		}
		&.flex-nav-prev a{
			background-image: url(../images/hero/desktop/left.svg);
			left: 70px !important;
			@media only screen and (max-width: 1050px) {
				left: 30px !important;
			}
			@media only screen and (max-width: 767px) {
				left: 6px !important;
				background-image: url(../images/hero/mobile/left.svg)
			}

		}
	}
	ul{
		@include no-format;
		li{
			margin-bottom: 0;
		}
	}
	&__table{
		height: 500px;
		width: 100%;
		display: table;
	}
	&__cell{
		vertical-align: middle;
		display: table-cell;
		width:100%;
		@include clearfix;
	}
	&__text{
		float: left;
		position: relative;
		box-sizing:border-box;;
		max-width: 580px;
		width:100%;
		padding:40px 10px;
		text-align: center;
		@media only screen and (max-width: 1050px) {
			margin:0 auto;
			float: none;
		}
		@media only screen and (max-width: 767px) {
			background-color:$green;
			box-sizing:border-box;
			padding:10px 10px;
			max-width: 100%;
		}
		&::before{
			content:'';
			display: block;
			background-color: $green;
			position:absolute;
			top:0;
			left:0;
			right: 0;
			bottom:0;
			opacity:0.925;
		}
		*{
			display: block;
			position:relative;
			z-index: 2;
			padding: 0 15px 0;
			margin-left:auto;
			margin-right: auto;
			color:$white;
		}
		.category-description {
			padding: 0 15px;
		}
		h1,h2,h3,h4,h5,h6{
			@media only screen and (min-width: 768px) {
				margin-bottom:25px !important;
				padding-bottom: 27px !important;
			}
			margin-bottom: 10px;
			position:relative;
			font-family: $abril;
			font-size: 36px !important;
			line-height: 43px !important;
			font-weight: 400;
			@media only screen and (max-width: 767px) {
				font-size: 28px !important;
				line-height: 35px !important;
			}
			@media only screen and (min-width: 768px) {
				&::after{
					content:'';
					display: block;
					position:absolute;
					width: 50px;
					height: 2px;
					background-color:$white;
					left: 50%;
					bottom:0;
					transform:translateX(-50%);
				}
			}
		}
		p{
			font-family: $abril !important;
			margin-bottom: 15px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		a{
			@include inline;
			vertical-align: middle;
			max-width: 335px;
			font-size: 18px;
			line-height: 1.8;
			color:$white;
			text-decoration: none;
			@media only screen and (max-width: 767px) {
				font-size: 14px;
				line-height: 24px;
			}
			&::after{
				content: '';
				@include inline;
				width: 13px;
				height: 10px;
				background-size: 13px 10px;
				background-repeat: no-repeat;
				background-position: center center;
				background-image: url(../images/icons/arrow-right.svg);
				margin-left: 15px;
				@media only screen and (max-width: 767px) {
					margin-left: 7px;
				}
			}

		}
	}
	@media only screen and (max-width: 767px) {
		.container{
			width:100%;
		}
		&__table,
		&__cell{
			display: block;
			height: auto;
		}
		&__mobile_image,
		&__mobile-image{
			display: block !important;
			width:100%;
			@include thumbnail;
			height: 240px;
		}
	}
}
.category-image{
	margin-bottom: 20px !important
}