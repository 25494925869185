.acf-map{
	height: 75vh !important;
	max-height: 600px;
}
body.contact-us .column.main{
	padding-bottom:0 !important;
	.post-entry{
		margin-bottom: 0 !important
	}
}
.page-wrapper .contact-us{
	header{
		margin-bottom: 50px;
		max-width:1060px;
		h1,h2,h3,h4,h5,h6{
			margin-bottom: 0.5em;
			font-family: $abril;
			color:$green;
		}
	}
	&__cta{
		@include clearfix;
		margin-bottom: 60px;
		@media only screen and (max-width: 767px) {
			margin-bottom: 0;
		}
		&--left{	
			float: left;
			max-width: 1060px;
			width: 66.666%;
			@media only screen and (max-width: 1024px) {
				width: 100%;
				float: none;
				max-width: 100%;
				margin-bottom: 30px;
			}
			@media only screen and (min-width: 960px) {
				.gform_wrapper{
					position:relative;
					ul{
						.gfield_checkbox,
						.gfield_radio{
							max-width: 620px;
						}
					}
					.gform_footer{
						position:absolute;
						bottom:0;
						right: 0;
						input{
							min-width: 250px !important;
						}
					}
				}
			}
			.validation_message{
				display: none;
			}
			.validation_error{
				text-align: center;
				display: block;
				color:$red;
				font-weight: bold;
				margin-bottom: 20px;
			}
			ul{
				@include no-format;
				li{
					display: block;
					width: 100%;
					text-align: center;
					&.gfield{
						margin-bottom: 20px;
					}
				}
				.gfield_error{ 
					input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), 
					textarea, 
					select{
						border-color:$red !important;
					}
					input[type="checkbox"],
					input[type="radio"]{
						& + label{
							color:$red !important;
						}	
					}
				}
				label{
					display: none;
				}
				input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
				select{
					line-height: 48px;
					width: 100%;
					display: block;
					box-sizing: border-box;
					padding:0 15px;
					font-size: 16px;
					border:none;
					box-shadow:none;
					appearance:none;
					height: 50px;
					@include transition(0.2s);
					border-radius:$radius;
					border:1px solid $border;
					@include transition(0.2s);
					background-position: right 15px center;
					&.mage-error{
						background-color:salmon;
					}
					&:focus{
						&:placeholder,
						&::placeholder{
							opacity:0;
						}
					}
				}
				textarea{
					line-height: 1.8;
					width: 100%;
					display: block;
					box-sizing: border-box;
					padding:10px 15px;
					font-size: 16px;
					border:none;
					box-shadow:none;
					appearance:none;
					height: auto;
					@include transition(0.2s);
					border-radius:$radius;
					border:1px solid $border;
					@include transition(0.2s);
					background-position: right 15px center;
				}
				.gfield_checkbox,
				.gfield_radio{
					margin-top: 40px;
					max-width: 620px;
					display: block;
					@media only screen and (min-width: 960px) {
						width: calc(100% - 300px);
					}
					li{
						position:relative;
					}
					label{
						display: block !important;
					}
				}
				input[type="checkbox"],
				input[type="radio"]{
					position:absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
					display: block;
					width: 100%;
					height: 100%;
					z-index:2;
					opacity:0;
					& + label{
						display: block;
						position:relative;
						padding-left:45px;
						width: 100%;
						text-align: left;
						z-index: 1;
						box-sizing:border-box;;
						&::before{
							content:'';
							display: block;
							position:absolute;
							top:0;
							left: 1px;
							border:1px solid $border;
							border-radius:$radius;
							width: 26px;
							height: 26px;
							box-sizing:border-box;
							background-size:15px 15px;
							background-repeat:no-repeat;
							background-position: center center;
						}
					}
					&:checked + label{
						&::before{
							background-image:url(../images/input/crossed.svg);
						}
					}
				}
			}
		}
		&--right{
			float: right;
			max-width: 380px;
			width: calc(33.3333% - 20px);
			@media only screen and (max-width: 1024px) {
				width: 100%;
				float: none;
				max-width: 100%;
			}
			h4{
				font-size: 20px;
				line-height: 43px;
				margin-bottom: 12px;
			}
			ul{
				@include no-format;
				font-size: 0;
				line-height: 0;
				li{
					display: block;
					width: 100%;
					border-bottom:1px solid rgba(187, 187, 187, 0.3);
					padding-bottom: 20px;
					margin-bottom: 20px;
					@media only screen and (min-width: 1025px) {
						&:last-child{
							margin-bottom: 0 !important
						}
						&:first-child{
							border-top:1px solid rgba(187, 187, 187, 0.3);
							padding-top: 20px;
						}
					}
					@media only screen and (max-width: 1024px) {
						padding-bottom:20px;
						padding-top: 20px;
						@include inline;
						vertical-align: top;
						border-top:1px solid rgba(187, 187, 187, 0.3);
						width: 50%;
						border-bottom:none;
						&:nth-child(odd):last-child{
							width: 100%;
						}
					}
					@media only screen and (max-width: 767px) {
						width: 100%;
					}
					li{
						padding:0;
						margin:0;
						border:none;
						width: 100%;
						a{
							text-decoration: none;
							display: block;
							width: 100%;
							text-align: left;
							box-sizing:border-box;;
							padding:0 0 0 50px;
							background-position: left center;
							background-repeat: no-repeat;
							line-height: 46px;
						}
					}
					.contact_icons{
						&--tel a{
							background-image: url(../images/icons/telephone.svg);
							background-size: 25px 47px;
							background-position: left 5px center;
						}
						&--email a{
							background-image: url(../images/icons/email.svg);
							background-size: 28px 20px;
						}
					}
				}
			}
			dl{
				display: block;
				@include clearfix;
				font-size: 0;
				line-height: 0;
				margin-bottom: 0;
				dt,
				dd{
					width: 50%;
					@include inline;
					vertical-align: top;
					font-size: 16px;
					line-height: 1.8;
					font-weight: 400;
					margin-bottom: 0;
				}
				dt:first-child{
					font-weight: bold;
				}
				dd:nth-child(2){
					font-weight: bold;
				}
			}
		}
	}
}
:root .ie-styles .page-wrapper .contact-us__cta--right ul li .contact_icons--tel a{
	background-size:auto !important;
}
:root body.no-spacing-bottom .page-wrapper{
	.column.main,
	.post-entry{
		margin-bottom: 0;
		padding-bottom: 0;
	}
}