.finance-banners{
	overflow: hidden;
	margin-bottom: 30.5px;
	.container{
		@media only screen and (min-width: 768px) {
			padding-bottom:60px;
			border-bottom:1px solid rgba(187, 187, 187, 0.3);
		}
	}
	@media only screen and (max-width: 1050px) {
		margin-bottom: 40px;
	}
	@media only screen and (max-width: 1050px) {
		margin-bottom: 50px;
	}
	.cell{
		vertical-align: middle;
	}
	ul{
		@include no-format;
		margin-left: -10px;
		margin-right: -10px;
		font-size: 0;
		line-height: 0;
		li{
			width: calc(50% - 20px);
			margin:0 10px 0;
			@include inline;
			vertical-align: middle;
			padding:15px 20px;
			box-sizing:border-box;
			border:2px solid $blue;
			@include clearfix;
			position:relative;
			@media only screen and (max-width: 1050px) {
				padding:10px 15px;
			}
			@media only screen and (max-width: 800px) {
				width: calc(100% - 20px);
				margin:0 10px 10px;
				&:last-child{
					margin-bottom: 0;
				}
			}
			.wrapper{
				@include clearfix;
			}
		}
	}
	&__text{
		float:left;
		width: calc(100% - 170px);
		@media only screen and (max-width: 1050px) {
			width: calc(100% - 100px);
		}
		@media only screen and (max-width: 800px) {
			width: calc(100% - 80px;)
		}
		display: block;
		h1,h2,h3,h4,h5,h6{
			font-size: 32px;
			line-height: 39px;
			color:$blue;
			margin-bottom: 0;
			@media only screen and (max-width: 1050px) {
				font-size: 20px;
				line-height: 1.8;
			}
			@media only screen and (max-width: 800px) {
				font-size: 14px;
			}
		}
		p{
			font-size: 18px;
			line-height: 22px;
			color:$blue;
			margin-bottom: 0;
			@media only screen and (max-width: 1050px) {
				font-size: 14px;
				line-height: 18px;
			}
			@media only screen and (max-width: 800px) {
				font-size: 10px;
			}
		}
	}

	img{
		max-width: 160px;
		height: auto;
		width: auto;
		display: block;
		position: absolute;
		right: 20px;
		top:50%;
		transform:translateY(-50%);
		@media only screen and (max-width: 1050px) {
			max-width: 85px;
			right:15px;
		}
		@media only screen and (max-width: 800px) {
			max-width: 75px;
		}
	}
}