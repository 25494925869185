:root body .page-wrapper .messages{
	.message{
		padding:10px 20px !important;
		z-index: 1;
	}
	*{
		font-size: 16px;
		text-align: center;
		padding:0;
		font-family: $abril;
		&::before{
			font-family:'luma-icons';
			position: static;
			vertical-align: text-bottom;
			margin-right: 20px;
		}
	}
}
:root body .page-wrapper .message{
	padding:10px 20px !important;
	*{
		font-size: 16px;
		text-align: center;
		padding:0;
		font-family: $abril;
		&::before{
			font-family:'luma-icons';
			position: static;
			vertical-align: text-bottom;
			margin-right: 20px;
		}
	}
}