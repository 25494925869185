/* ==========================================================================
   3.2. BLOCK STYLES
   ========================================================================== */
   // Hero
   @import "section/hero";
   // Carousel
   @import "section/carousel";
   // Promos
   @import "section/promos";
   // Banner
   @import "section/banner";
   // Finance-Banner
   @import "section/finance-banner";
   // Wysiwyg
   @import "section/wysiwyg";
   // Gallery
   @import "section/gallery";
   // full-width-image
   @import "section/full-width-image";
   // Accordion-block
   @import "section/accordion-block";
   // Product Carousel
   // @import "section/product-carousel";
   // BLOCKS
   // @import "section/sassFile";


/* ==========================================================================
   3.3. BASE STYLES
   ========================================================================== */
   // blog
   @import "page/blog";
   // contact
   @import "page/contact";
   // faq
   @import "page/faq";
   // generic
   @import "page/generic";
   // PAGES
   // @import "page/sassFile";
   // PAGES
   // @import "page/sassFile";
:root body.single-wp-blog .page-wrapper #maincontent{
   .hero{
      margin-bottom: 20px;
   }
}


:root body .page-wrapper #maincontent {
   .carousel--products--blog{  
      header{
         padding-top:60px !important;
      }
   } 
   .blog-single__related-blogs{
      header{
         margin:60px auto !important;
      }
   }
}