.no-format {list-style:none; padding:0; margin:0}
.ib {display:inline-block;*display:inline;zoom:1}
.indent {text-transform:capitalize; text-align:left; text-indent:-99999px}
.rel {position:relative}
.box {-webkit-box-sizing: border-box;-moz-box-sizing: border-box; box-sizing: border-box}
.f-left{float:left;}
.f-right{float:right;}
.thumbnail{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;;
}
.text-align{
  &.center{text-align:center}
  &.left{text-align:left}
  &.right{text-align:right;}
}
.table{width: 100%;height: 100%;display: table;}
.cell{
  display:table-cell;width:100%;
  &.middle{vertical-align:middle;}
  &.bottom{vertical-align:bottom;}
  &.top{vertical-align:top;}
}
.modals-wrapper,
.modals-wrapper aside{
  z-index:999999999 !important;
  .modal-footer button{
    margin-bottom:5px !important;
    &:last-child{
      margin-bottom: 0 !important;
    }
    &::before,
    &::after{
      display: none;
    }
    width: 100% !important;
    background-color: $green !important;
    color:$white !important;
    border:none !important;
    @include transition(0.2s);
    font-size: 16px !important;
    line-height: 28px !important;
    font-weight: bold !important;
    font-family: $proxima !important;
    color:$white !important;
    min-width: 160px !important;
    padding:9px 20px !important;
    text-align: center !important;
    @include inline;
    vertical-align: middle !important;
    border-radius:$radius !important;
    box-sizing:border-box !important;
    border:2px solid $green !important;
    box-shadow:none !important;
      text-decoration: none !important;
    *{
      font-size: inherit !important;
      line-height: inherit !important;
      font-weight: inherit !important;
      color:inherit !important;
      width: auto !important;
      height: auto !important;
      clip: none !important;
      position:static !important;
      overflow: visible !important;
      text-transform: none !important;
      text-decoration: none !important;
    }
    &:hover{
      color:$green !important;
      background-color:$white !important;
    }
  }
}
.modals-wrapper .modals-overlay{
  z-index:999999978 !important;
}
.ie-styles.wordpress-post-view #maincontent{
  flex: none !important
}
.home-page{
  .columns,
  .column.main{
    margin-bottom: 0 !important;
    padding-bottom:0 !important;
  }
}


.page-wrapper{
  overflow: hidden;
}
.main__full-desc{
  clear: both;
}


#notice-cookie-block{
  background-color:$dgreen;
  text-align: center;
  padding:10px !important;
  p{
    span, strong{
      color:$white;
  
    }
    a{
      color:$green;
    }
  }
  button{
    background-color: $green;
    color:$white;
    border:none;
    @include transition(0.2s);
    font-size: 16px !important;
    line-height: 28px !important;
    font-weight: bold;
    font-family: $proxima;
    color:$white;
    min-width: 160px;
    padding:10px 20px !important;
    text-align: center;
    @include inline;
    vertical-align: middle;
    border-radius:$radius;
    box-sizing:border-box;
    border:1px solid $green;
    box-shadow:none;
    text-decoration: none;
    *{
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      color:inherit;
      width: auto;
      height: auto;
      clip: none;
      position:static;
      overflow: visible;
      text-transform: none;
      text-decoration: none;
    }
    &:hover{
      color:$green;
      background-color:transparent;
    }
  }

}
.toolbar-products {
  position: relative
}
.category-description {
  p {
    text-align: left!important;
  }
}