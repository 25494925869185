.post-view__article{
	margin-bottom: 65px;
	@media only screen and (max-width: 959px) {
		margin-bottom: 30px;
	}
	h1,h2,h3,h4,h5,h6{
		font-family: $abril;
		color:$mgreen;

	}
	p, li{
		color:#4A4A4A;
		&:last-child{
			margin-bottom: 0;
		}
	}
	figure{
		margin:60px auto;
		&:first-child{
			margin-top: 0 !important;
		}
		&:last-child{
			margin-bottom: 0 !important;
		}
		@media only screen and (max-width: 959px) {
			margin:30px auto;
		}
	}
}