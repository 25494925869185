.post-list-wrapper{
	overflow: hidden;
}
.post-list-item{
	position:relative;
	min-height: 380px;
	background-size:cover;
	background-position:center center;
	background-repeat:no-repeat;
	overflow: hidden;
	.sticker{
		&--win{
			position:absolute;
			top:25px;
			right: 25px;
			height: 85px;
			width: 85px;
			background-color:$lgreen;
			color:$white;
			font-family: $abril;
			text-decoration: none;
			text-transform: uppercase;
			font-size: 28px;
			line-height:85px;
			display: block;
			text-align: center;
			border-radius:999px;

		}
		&--featured{
			background-color:$green;
			color:$white;
			font-family: $abril;
			font-size: 12px;
			line-height: 22px;
			text-transform: uppercase;
			padding:10px 20px;
			position:absolute;
			top:35px;
			right: 35px;
			letter-spacing: 0.3em;
		}
	}
	&:hover figure{
		transform:scale(1.1);
	}
	figure{
		@include transition(0.2s);
		position: absolute;
		z-index: 1;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		&::before{
			content:'';
			z-index: 2;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			width: 100%;
			height: 100%;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.5+100 */
			background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
		}
	}
	&__shadow{
		content:'';
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 100%;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.5+100 */
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
	
	}
	&__sticker{
		position:absolute;
		display: block;
		z-index: 3;
		top:35px;
		right: 40px;
		background-color: #459280;
		padding:10px 15px;
		font-family:$abril;
		font-size: 12px;
		line-height: 22px;
		text-transform: uppercase;
		color:$white;
		letter-spacing: 0.2em;
	}
	&__content{
		position:absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	&__table{
		@include table;
		position:relative;
		z-index: 2;
	}
	&__cell{
		@include cell;
		vertical-align: bottom;
	}
	h2{
		font-size: 24px;
		line-height:33px;
		max-height: 66px;
		overflow: hidden;
		display: block;
		max-width: 100%;
		font-family: $abril;
		color:$white !important;
		margin-bottom: 20px;
		a{
			text-decoration: none;
			color:inherit !important;
			&:hover{
				color:$green !important;
			}
		}
	}
	.button:not(:hover){
		color:$white;
	}
	ul{
		@include no-format;
		text-align: left;
		margin-bottom: 16px;
	}
	.container{
		width: 100%;
		display: block;
		box-sizing:border-box;
		padding:40px;
	}
	li{
		@include inline;
		vertical-align: middle;
		margin-bottom: 0;
		&.date{
			@include inline;
			vertical-align: middle;
			font-size: 12px;
			line-height: 26px;
			color:$white;
		}
		&.cat{
			font-size: 0;
			line-height: 0;
			@include inline;
			vertical-align: middle;
			a{
				margin: 0 2px 2px 0;
				@include inline;
				vertical-align: middle;
				padding:2px 8px;
				font-size: 12px;
				line-height: 22px;
				background-color: #7D446D;
				color:$white !important;
				text-transform: uppercase;
				text-decoration: none;
				&:hover{
					background-color:$green;
				}
			}
		}
	}
}