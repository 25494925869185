#menu-toggle {
    float: right;
    width: 25px;
    position: relative;
    cursor: pointer;
    display: block;
    height: 20px;
    z-index: 9999;
}
#menu-toggle .menu-line {
    background-color: #fff;
    height: 3px;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}
#menu-toggle .line-1 {
    top: 0;
}
#menu-toggle .line-2,
#menu-toggle .line-3 {
    top: 8px;
}    
#menu-toggle .line-4 {
    top: 16px;
}
#menu-toggle.open .line-1,
#menu-toggle.open .line-4 {
    opacity: 0;
}
#menu-toggle.open .line-2 {
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}
#menu-toggle.open .line-3 {
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
    transform: rotate(-45deg);
}
#menu-toggle.open .line-2,
#menu-toggle.open .line-3 {
    -ms-transform-origin: 50% 50%; /* IE 9 */
    -webkit-transform-origin: 50% 50%; /* Chrome, Safari, Opera */
    transform-origin: 50% 50%;
}
#menu-toggle:hover .menu-line,
#menu-toggle:focus .menu-line {
    background-color: #04b3da;
}