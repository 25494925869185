:root body .page-header{
	border-bottom:1px solid $border;
	margin-bottom: 0 !important;
	.minicart-wrapper .product .actions{
		float: none;
		margin:20px auto 0;
	}
	@media only screen and (min-width: 1051px) {
		position:relative;
		z-index: 9999;
	}
	&__top{
		@include clearfix;
	}
	&__logo{
		margin: 33px 0;
		display: block;
		float: left;
		img{
			max-width: 100%;
			height: auto;
		}
		@media only screen and (max-width: 1050px) {
			float: none;
			margin:20px auto;
			max-width: 226px;
		}
		@media only screen and (max-width: 767px) {
			max-width:177px;
		}
		&:hover{
			opacity:0.8;
		}
	}
	&__basket {
		float: left;
		margin: 35px;
		display: none;

		a {
			color: $white;
		}
	}
	&__cta{
		display: block;
		float: right;
		margin:33px 0;
		@media only screen and (max-width: 1050px) {
			display: none;
		}
		& > div{
			a{
				font-weight:bold;
				color:$green;
				@include inline;
				vertical-align: middle;
				margin-left: 10px;
				&:hover{
					color:$text;
				}
			}
		}
		& > nav{
			li{
				font-weight: 300;
				padding-right:35px;
				position:relative;
				&::after{
					content:'';
					display: block;
					width: 25px;
					height: 27px;
					background-size: 27px 25px;
					background-repeat:no-repeat;
					background-position:center center;
					background-repeat:no-repeat;
					position: absolute;
					top:50%;
					transform:translateY(-50%);
					right: 0;
					background-image:url(../images/header/account.svg);
				}
			}
			a{
				text-decoration: none;
				@include inline;
				vertical-align: top;
				line-height: inherit;
				color:$text;
				position:relative;
				&::after{
					content:'';
					display: block;
					position:absolute;
					top:calc(100% - 5px);
					left: 0;
					@include transition(0.2s);
					height: 1px;
					width: 0%;
					background-color:$green;
				}
				&:hover{
					color:$green;
					&::after{
						width: 100%;
					}
				}
			}
		}

		& > div,
		& > nav{
			@include inline;
			vertical-align: middle;
			margin-left: 55px;
			ul{
				@include no-format;
			}
			p,
			li{
				margin-bottom: 0;
				font-size: 18px;
				font-weight: 300;
				a{
					text-decoration: none;
				}
			}
			&:first-child{
				margin-left: 0;
			}
		}
	}
	&__nav-toggle{}
	.container.mega-container {
		//position:relative;
	}
	&__nav{
		position:relative;
		background-color:$mgreen;
		@media only screen and (min-width: 1051px) {
			z-index: 99999999;
		}
		@media only screen and (max-width: 1050px) {
			&--tel{
				display: block !important;
				position:relative;
				width: 25%;
				float: left;
				height: 50px;
				span{
					position:absolute;
					font-size: 0;
					line-height: 0;
					text-indent:-9999px;
					color:transparent;
					display: block;
					width: 26px;
					height: 25px;
					top:50%;
					left: 50%;
					transform:translate(-50%,-50%);
					background-size:26px 25px;
					background-repeat:no-repeat;
					background-position:center center;
					background-image:url(../images/header/telephone.svg);
				}
			}
		}
		.container{
			@include clearfix;
		}
		.nav-sections{
			width:65%;
			.navigation{
				background:transparent !important;
			}
			@media only screen and (min-width: 1051px) {
				float:left;
				margin-bottom: 0;
				background: transparent;
				.navigation{background: transparent; position: static;}
				ul{
					position:static;
					@include no-format;
					li.level0,
					li.level0 > a{
						padding:0;
						text-decoration: none;
					}
					li.level0 .submenu a{
						padding:0 20px;
						text-decoration: none;
					}
					li.level0{
						margin-right: 30px;
						@media only screen and (min-width: 1080px) {
							margin-right: 40px;
						}
						position:static;
						&.parent a{
							padding-right:20px;
						}
						& > a{
							color:$white;
							line-height: 50px;
							border:none;
						}
						&.active > a,
						&:hover > a{
							color:$green;
						}
					}
					// ul{
					// 	position:absolute;
					// 	top:100% !important;
					// 	left: 0 !important;
					// 	right: 0 !important;
					// 	width: 100%;
					// 	background-color:$white;
					// 	a{
					// 		max-width:1600px;
					// 		margin-left: auto;
					// 		margin-right: auto;
					// 		display:block;
					// 		text-decoration: none;
					// 	}
					// }
				}
			}
			@media only screen and (max-width: 1050px) {
				position:static;
				left: 0;
				right: 0;
				bottom:0;
				top:0;
				background-color:transparent;
				border:none;
				margin:0;
				ul{
					@include no-format;
					a{
						box-sizing:border-box;
						border:none !important;
						text-decoration: none;
						span{
							margin:0 !important;
							text-indent: 0 !important
						}
					}
					li{ 
						position:relative;
						a{
							padding:0 10px !important;
						}
						&.active > a,
						&:hover > a{
							color:$green;
						}
					}
					li.level0{
						display: block;
						width: 100%;
						margin:0;
						text-align: left;
						position:relative;
						padding:0 0;
						box-sizing:border-box;;
						border-bottom:1px solid $border;
						.ui-icon-carat-1-e{
							display: none !important;
						}
						&.parent > a,
						.has-mobile-menu > a,	
						.mobile-menu h4 a{
							padding-right:20px;
							background-image:none !important;
							box-sizing:border-box;
							position:relative;
							&::after{
								content:'';
								width: 5px !important;
								height: 10px !important;
							    color: #4A4A4A;
							    font-weight: 400;
							    line-height: 50px;
							    border: none;
							    font-size: 18px;
							    line-height: 50px;
							    height: 50px;
							    display: block;
							    width: 100%;
							    background-position: right center;
							    background-repeat: no-repeat;
							    background-size: 5px 10px;
							    background-image: url(../images/icons/chevron-right.svg);
							    transform:rotate(90deg) translateX(-50%);
							    transform-origin:center center;
							    top:50%;
							    position:absolute;
							    right: 5px;
								@media only screen and (min-width: 1051px) {
									transform: translateY(-50%);
									right: 0px;
								}
							
							}
							&.ui-state-active::after{
								transform: translateY(-50%);
								right: 0px;
							}
							.ui-icon-carat-1-e{
								display: none !important;
							}
						}
						.mobile-menu h4 a.ui-state-active::after{
							    transform:rotate(-90deg) translateX(-50%);
							right: 5px;
						}
						// ul{
						// 	@media only screen and (min-width: 768px) {
						// 		display: none !important;
						// 	}
						// }
						a{
							color:$text;
							font-weight: 400;
							line-height: 50px;
							border:none;
							font-size: 18px;
							line-height: 50px;
							height: 50px;
							display: block;
							width: 100%;
							background-position: right center;
							background-repeat:no-repeat;
							background-size:5px 10px;
							//background-image:url(../images/icons/chevron-right.svg)
						}
						div.level2 a {
							line-height: 1.4em;
							height: auto;
						}
						
					}
				}

				.nav-sections-item-content{
					margin:0 auto;
					width:100%;
					display: none !important;
					float: none;
					clear: both;
					nav{
						background-color:transparent !important;
						padding:0 !important;
						overflow: auto;
						max-height:calc(100vh - 120px);
						height: 100vh;
						margin:5px 0;
						width:100%;
					}
				}
				.section-item-title.nav-sections-item-title:nth-child(3){
					display: none !important;
					vertical-align: middle;
					font-size: 18px;
					line-height: 22px;
					padding:0 !important;
				}

				&.open{
					position:fixed;
					top:0;
					left: 0;
					right: 0;
					bottom:0;
					width:100%;
					height: 100vh;
					background-color:$white;
					z-index: 9999;
					box-sizing:border-box;
					.nav-sections-item-content{
						display: block !important;
						padding:0px;
						box-sizing:border-box;;
					}
					.section-item-title.nav-sections-item-title:nth-child(3){
						display: block !important;
						padding:0 10px !important;
						box-sizing:border-box;;
						height: 55px;
						line-height: 55px;
						position: relative;
						outline:none !important;
						width: 100%;
						text-align: left;
						background-color:#FAFAFA !important;
						&::after{
							content:'';
							display: block;
							width: 18px;
							height: 20px;
							background-size: 18px 20px;
							background-position:no-repeat;
							background-position: right center;
							background-image:url(../images/header/account.svg);
							position:absolute;
							right: 10px;
							top:50%;
							transform:translateY(-50%);
						}
						a{
							@include inline;
							vertical-align: middle;
							color:$text;
							font-size: 18px;
							line-height: 50px;
							text-decoration: none;
							outline:none !important;
						}
					}
					.section-item-title.nav-sections-item-title:first-child {
						display: block !important;
						background-color:$dgreen;
						box-sizing:border-box;
						padding:0 10px;
						width: 100%;
						height: 55px;
						position:relative;
						border:none !important;
						a{
							position:absolute;
							display: block;
							width: 100%;
							box-sizing:border-box;
							text-decoration:none;
							padding: 0 10px;
							left: 0;
							right: 0;
							bottom:0;
							top:0;
							line-height:55px;
							color:$white;
							font-size: 	16px;
							text-align: left;
							@include transition(0s);
							&::before{
								content:'';
								display: block;
								position:absolute;
								right:10px;
								top:50%;
								transform:translateY(-50%);
								background-size:15px 15px;
								width: 15px;
								height: 15px;
								background-position:center center;
								background-repeat:no-repeat;;
								background-image:url(../images/header/close.svg);
							}
						}
					}
				}
				&:not(.open){
					float:left;
					width: 20%;
					overflow: hidden;
					.section-item-title.nav-sections-item-title:first-child {
						height: auto;
						display: block;
						float: none;
						padding:0;
						margin:0 auto;
						border:none;
						margin:12.5px auto;
						background-color:transparent;
						border:none;
						width: 30px;
						height: 25px;
						position:relative;
						background-position:center center;
						background-repeat:no-repeat;
						background-size:30px 30px;
						background-image:url(../images/header/hamburger.svg);
							@include transition(0s);
						a{
							position:absolute;
							top:0;
							left: 0;
							right: 0;
							bottom:0;
							display: block;
							width: 100%;
							height: 100%;
							opacity:0;
							@include transition(0s);
						}
					}
				}
			}
		}
		&--right{
			float: right;
			@media only screen and (max-width: 1050px) {
				width: 80%;
			}
		}
		.block-search{
			float: left;
			box-sizing:border-box;
			padding:0;
			input{position:static !important;}
			@media only screen and (min-width: 1051px) {
				width: 410px;			
				padding:7px 0;
				@media only screen and (max-width: 1310px) {
					width: 190px;
				}
				.block-header{
					display: none;
				}
			}
			@media only screen and (max-width: 1050px) {
				width: 25%;
				margin:0 auto !important;
				input[type="text"],
				input[type="search"]{
					left: auto !important
				}
				.block-title{
					display: block;
				}
				&:not(.tablet-open){
					width: 25%;
					height: auto;
					padding:12.5px 0;
					.block-title{
						margin:0 auto;
						width: 25px;
						height: 25px;
						background-size:25px 25px;
						background-repeat:no-repeat;
						background-position:center center;
						background-image:url(../images/header/search.svg);
						margin:0 auto;
						strong{
							width: 100%;
							height: 100%;
							overflow: hidden;
							opacity:0;
						}
					}
					.block-content{
						display: none;			
					}
				}
				&.tablet-open{
					position:fixed;
					top:0;
					left: 0;
					right: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					display: block;
					background-color:rgba(255,255,255,0.8);
					z-index: 9999999;
					.block-title{
						position:absolute;
						height: 55px;
						width: 100%;
						left:0;
						top:0;
						right: 0;
						background-color:$dgreen;
						strong{
							position:absolute;
							display: block;
							width: 100%;
							box-sizing:border-box;
							text-decoration:none;
							padding: 0 10px;
							left: 0;
							right: 0;
							bottom:0;
							top:0;
							line-height:55px;
							color:$white;
							font-size: 	16px;
							cursor:pointer;
							&::before{
								content:'';
								display: block;
								position:absolute;
								right:10px;
								top:50%;
								transform:translateY(-50%);
								background-size:15px 15px;
								width: 15px;
								height: 15px;
								background-position:center center;
								background-repeat:no-repeat;;
								background-image:url(../images/header/close.svg);
							}
						}
					}
					.block-content{
						display: block;
						position:absolute;
						width: 95%;
						max-height: 75vh;
						top:50%;
						left: 50%;
						float:none;
						transform:translate(-50%,-50%);
					}
				}
			}
		}
		.minisearch{
			width: 100%;
			label{
				display: none;
			}
			input[type="text"],
			input[type="search"]{
				height: 36px;
				line-height: 36px;
				background-color: $dgreen;
				border-radius: $radius;
				border:none;
				color:$white;
				width: 100%;
				padding-right:40px;
				box-sizing:border-box;
				@media only screen and (max-width: 1050px) {
					height: 55px;
					line-height: 55px;
					padding-right:60px;
				}
				&:placeholder{
					color:$white;
				}
				&::placeholder{
					color:$white;
				}
			}
			button{
				position:absolute;
				top:50%;
				transform:translateY(-50%);
				opacity:0.8 !important;
				@include transition(0.2s);
				width: 36px;
				height: 36px;
				right: 0;
				@media only screen and (max-width: 1050px) {
					height: 55px;
					width: 55px;
				}
				&::before{
					position:absolute;
					top:50%;
					left: 50%;
					transform:translate(-50%,-50%);
					width: 17px;
					height: 18px;
					font-size: 0;
					line-height: 0;
					background-size: 17px 16px !important;
					background-repeat:no-repeat !important;
					background-position:center center !important;
					background-image:url(../images/header/search.svg) !important;
				}
				&:hover{
					opacity:1 !important;
				}
			}
		}
		&--wishlist{
			float: left;
			font-size: 0;
			line-height: 0;
			width: 28px;
			height: 50px;
			background-size: 28px 25px;
			background-repeat:no-repeat;;
			background-position: center center;
			background-image:url(../images/header/favourites.svg);
			position:relative;
			box-sizing:border-box;
			margin:0 24px 0 24px;
			.counter.qty{
				background-color:$gold;
				width: 20px;
				height: 20px;
				position:absolute;
				top:5px;
				right: -10px;
				padding:0;
				border-radius:999px;
				color:$white;
				font-size: 14px;
				font-family: $proxima;
				font-weight: 500;
				text-align: center;
				line-height: 20px;
				@media only screen and (max-width: 1050px) {
					right: auto;
					left: 50%;
					margin-left: 15px;
					transform:translateX(-50%);
				}

			}
			@media only screen and (max-width: 1050px) {
				width:25%;
				margin:0 !important;
			}
		}
		.minicart-wrapper{
			margin-left: 20px;
			float: right;
			width: 29px;
			height: 25px;
			margin:12.5px auto;
			position:relative;
			.block-minicart{
				right: 0;
				margin-top: 10px;
				padding-bottom:0;
				.block-content > .actions > .primary{
					margin:0 auto 20px;
				}
				 .items-total,
				 .sub-total{
				 	margin-left: 0;
				 }
				 .sub-total{
				 	margin-right: 0
				 }

				a{
					color:$green;
					text-decoration: none;
					&:hover{
						color:$text;
					}
				}
				.product-item-pricing,
				.product-item-name{
					*{
						font-size: 16px;
						line-height: 1.2;
						font-weight: 400;
					}
					input{
						border-top:none;
						border-left:none;
						border-right:none;
						border-bottom:2px solid $green;
						min-width: 75px;
						text-align: left;
					}
				}
				@media only screen and (min-width: 1051px) {
					&::before{
						right: 5px;
					}
					&::after{
						right: 5px;
					}
				}
			}
			@media only screen and (min-width: 1051px) {
				.block-minicart .minicart-items-wrapper{
					height: auto;
					margin:0 auto;
					max-height: 40vh !important;
					overflow: auto !important;
				}
			}
			@media only screen and (max-width: 1050px) {
				width: 25%;
				.block-minicart{
					z-index: 99999;
					position: fixed;
					top:0;
					bottom:0;
					left: 0;
					right: 0;
					width: 100%;
					height: 100%;
					box-sizing:border-box;
					margin:0;
					border:none;
					padding-top:70px;
					#minicart-content-wrapper{
						height: 100%;
						overflow: visible !important;
						overflow-x: hidden !important;
						margin:0;
						// max-height: calc(100vh - 120px);
					}
					.minicart-items-wrapper{
						height: auto;
						margin:0 auto;
					}
					#btn-minicart-close{
						position:absolute;
						height: 55px;
						width: 100%;
						left:0;
						top:0;
						right: 0;
						background-color:$dgreen;
						border-radius:0px;
						&::before{
							content:'';
							display: block;
							position:absolute;
							right:10px;
							top:50%;
							transform:translateY(-50%);
							background-size:15px 15px;
							width: 15px;
							height: 15px;
							background-position:center center;
							background-repeat:no-repeat;;
							background-image:url(../images/header/close.svg);
							font-size: 0;
							line-height: 0;
							text-indent: -9999px;
						}
						span{
							position:absolute;
							display: block;
							width: 100% !important;
							height: 100% !important;
							clip: initial !important;
							box-sizing:border-box;
							text-decoration:none;
							padding: 0 10px;
							left: 0;
							right: 0;
							bottom:0;
							top:0;
							line-height:55px;
							color:$white;
							font-size: 	16px;
							cursor:pointer;
							text-align: left;
							
						}
					}
				}
			}
			.details-qty.qty{ 
				button{
					margin-top: 10px;
					margin-bottom: 0;
					position: static;
					float: none;
					display: block;
					width: 100%;
					background-color: $green;
					color:$white;
					border:none;
					@include transition(0.2s);
					font-size: 16px !important;
					line-height: 28px !important;
					font-weight: bold;
					font-family: $proxima;
					color:$white;
					min-width: 160px;
					padding:5px 5px !important;
					text-align: center;
					@include inline;
					vertical-align: middle;
					border-radius:$radius;
					box-sizing:border-box;
					border:2px solid $green;
					box-shadow:none;
						text-decoration: none;
					*{
						font-size: inherit;
						line-height: inherit;
						font-weight: inherit;
						color:inherit;
						width: auto;
						height: auto;
						clip: none;
						position:static;
						overflow: visible;
						text-transform: none;
						text-decoration: none;
					}
					&:hover{
						color:$green;
						background-color:$white;
					}
				}
			}
			.showcart{
				margin:0 auto;
				width: 29px;
				height: 25px;
				position:relative;
				display: block;
				&::before{
					font-size: 0;
					line-height: 0;
					text-indent: -999px;
					color: transparent;
					position:absolute;
					top:0;
					left: 0;
					right: 0;
					bottom:0;
					width: 100%;
					display: block;
					height: 100%;
					background-size:29px 25px;
					background-repeat: no-repeat;
					background-position:center center;
					background-image: url(../images/header/cart.svg);
				}
				.text{
					display: none;
				}
				.counter.qty{
					background-color:$gold;
					width: 20px;
					height: 20px;
					position:absolute;
					top:0;
					right: 0;
					transform:translate(50%, -50%);
					padding:0;
					border-radius:999px;
					.counter-label{
						display: none;
					}
					.counter-number{
						color:$white;
						font-size: 14px;
						font-family: $proxima;
						font-weight: 500;
						position:absolute;
						top:50%;
						left:50%;
						transform:translate(-50%,-50%);
						text-shadow: none !important;
						box-shadow: none !important;
						padding:0;
					}
				}
			}
		}
	}
	&__promos{
		@include no-format;
		text-align: center;
		display: block;
		width: 100%;
		font-size: 0;
		line-height: 0;
		display: none;
		li{
			@include inline;
			vertical-align: middle;
			width:33.333%;
			text-align: center;
			font-size: 16px;
			line-height: 20px;
			font-weight: bold;
			color:$pink;
			margin:20px 0;
			box-sizing:border-box;
			@media only screen and (min-width: 1051px) {
				&:not(:last-child){
					border-right:1px solid $border;
				}
			}
			&:before{
				content:'';
				@include inline;
				vertical-align: middle;
				background-size:contain;
				background-repeat:no-repeat;
				background-position:center center;
				width: 50px;
				height: 36px;
				margin-right: 25px;
			}
			a {
				color:$pink;
			}
		}
		&--first{
			&::before{
				background-image:url(../images/header/delivery.svg);
				background-size:50px 30px;
			}
		}
		&--center{
			&::before{
				background-image:url(../images/header/finance.svg);
				background-size:43px 36px;
			}
		}
		&--last{
			&::before{
				display: none !important
			}
		}
	}
	&.scrolled{
		.page-header__nav{
			position:fixed;
			top:0;
			left: 0;
			right: 0;
			width: 100%;
			z-index:999999999;
		}
	}
}
.sections.nav-sections{
	@media only screen and (max-width: 1050px) {
		nav.navigation{
			display: none;
			width: calc(100% - 20px);
			margin-left: auto;
			margin-right: auto;
			max-height: calc(100vh - 150px);
			overflow: auto;
		}
		&.open{
			nav.navigation{
				display: block
			}
			.section-item-title.nav-sections-item-title:first-child {
				float: none
			}

			.section-item-title.nav-sections-item-title:nth-child(3) {
				position:absolute !important;
				bottom:0;
				left: 0;
				right: 0;
				border:none;
				background-color:transparent;
			}
		}
	}
}
.nav-sections__sub-nav{
	display: none;
	&.desktop{
		position:absolute;
		left: 0;
		right: 0;
		top:100%;
		background-color:$green;
		visibility: hidden;
		display: block;
		opacity:0;
		z-index:-1;
		@media only screen and (max-width: 1050px) {
			display: none !important;
		}
		& > .container > ul {
			margin-left: -15px !important;
			margin-right: -15px !important;
			width: calc(100% + 30px);
			& > li{
				@include inline;
				vertical-align: middle;
				margin:0;
				& > a{
					padding:0 15px;
					line-height: 50px;
					height: 50px;
					display: block;
					text-decoration: none;
					font-weight: 400;
					color:$white;
					font-family: proxima-nova, sans-serif;
					&:hover{
						background-color: $white;
						color:$dgreen;
					}
				}
				&.has-megamenu{
					.megamenu{
						display: none;
						position:absolute;
						left: 50%;
						transform:translateX(-50%);
						right: 0;
						width: 100%;
						top:100%;
						background-color:#FAFAFA;
						padding:0 20px;
						max-width: 1640px;
					}
					&:hover .megamenu{
						display: block;
					}
				}
			}
		}
		
	}
	&.mobile{
		padding-left:20px !important;
		display: none;
		border-left:2px solid $border;
		@media only screen and (min-width: 1051px) {
			display: none !important
		}
		.nav-sections__mobile-menu{
			padding-left:20px !important;
			display: none;
			border-left:2px solid $border;
			
		}

		.mobile-menu--single{
			ul{
				display: none;
				padding-left:20px !important;
				border-left:2px solid $border;
				
			}
		}
	}
}
@media only screen and (min-width: 1051px) {
	.has-children:hover .nav-sections__sub-nav.desktop{
		visibility: visible;
		opacity:1;
		z-index:9999;
	}
}
.megamenu{
	&--wrapper{
		padding:25px 0;
	}
	&--single{}
	&--text{
		width: 33.333%;
		display: block;
		float: left;
		box-sizing:border-box;
		&:not(:first-child){
			padding-left:25px;
		}
		&:not(:last-child){
			border-right:1px solid $border;
		}
		h4{
			font-size: 18px;
			line-height: 25px;
			margin-bottom: 15px;
			font-family: $abril;
			color:#244856;
			font-weight:400;
		}
		li{
			margin:0;
			line-height: 40px;
			font-size: 16px;
			font-weight: 400;
			a{
				text-decoration: none;
				color:$text;
				&:hover{
					color:$green;
				}
			}
		}
	}
	.half{
		@include clearfix;
		width: 48%;
	}
	&--image{
		overflow: hidden;
		ul{
			@include no-format;
			@include clearfix;
			margin-left: -15px !important;
			margin-right: -15px !important;
			li{
				margin-bottom: 0;
			}
		}
		img{
			// max-width: 230px;
			height: auto;
			display: block;
			width: 100%;
		}
		a{
			display: block;
			padding:0 !important;
		}
		ul li{
			box-sizing:border-box;
			width: 33.3333%;
			max-width: 100%;
			padding: 0 15px;
			display: block;
			float: left;
		}
	}
}
@media only screen and (max-width: 1050px) {
	html,
	body{
		&.o-hidden{
			overflow: hidden;
			position:fixed;
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}
:root body.home-page,
:root body.page-products,
:root body.catalog-product-view{
	.page-wrapper .page-header__promos{
		display: block;
	}
}
.nav-sections-item-title {
	@media only screen and (max-width: 1050px) {
		display: none;
	}
}
/* VS MEGA MENU CLASSES */
//.ves-megamenu {
	.navigation {
		.ves-megamenu {
			.level0 {
				//&.green-drop {
					>.submenu.dropdown-menu {
						@media only screen and (min-width: 768px) {
							//position: static;
							background-color: #459280;
							padding: 0px 20px;
							border:0;
							.content-wrap {
								max-width: 1600px;
								margin-left: auto;
								margin-right: auto;
								width: calc(100% - 20px);
							}
							.nav-item.level1 {
								&.submenu-left.dropdown-submenu {
									position: static;
									>a {
										position: static;
										padding: 8px 20px;
									}
									&:hover {
										.submenu.dropdown-menu, .submenu.dropdown-mega {
											display: block;
										}
									}
								}
							}
							.level1 { //Green menu (2nd level)
								>.row { //If subcategory has no children, they appear as row
									width: auto;
									float: left;
									a {
										padding:8px 20px;
									}
								}
								&.dropdown-submenu {
									width: auto;
									float:left;
									> a {
										color:#fff;
										font-size: 16px;
										font-family:$proxima;

										&:hover {
											color:#000;
										}
									}
								}
								.submenu.dropdown-menu, .submenu.dropdown-mega { //White Menu (third level)
									background-color:#fafafa;
									width: 100% !important;
									position: absolute;
									left:0!important;
									top:43px!important;
									display: none;
									.row {
										line-height: 1.5em;
										display: inline-block;
										a {
											color:#000;
											&:hover {
												background:none;
											}
										}
									}
									.level2 {
										.row {
											display: block;

											a {
												padding: 10px 0;
												color:#4a4a4a;
											}
										}
									}
								}
							}
						}
					}
				//}
				&.dropdown-75-25 {
					@media only screen and (min-width: 768px) {
						.mega-col-level-1:first-child {
							width: 75%;
						}
						.mega-col-level-1:nth-child(2) {
							width: 25%;
						}
					}
				}
			}
		}
	}
//}

/* SEARCH AUTOCOMPLETE */

.mst-searchautocomplete__autocomplete {
	@media all and (min-width: 768px), print {
		&._active {
			min-width: 30rem;
		}
	}

	.mst-searchautocomplete__index-title {
		font-size:0.8rem;
		text-transform: capitalize;
		font-weight: 400;
	}
	ul {
		padding: 10px 0;
		li{
			padding: 0;
			a.title {
				font-size:14px;
			}
		}
	}
}
.mst-searchautocomplete__item {
	&.magento_catalog_product {
		.title {
			font-size:14px;
		}
		img {
			height:4rem;
		}
		.description {
			font-size:0.8rem;
		}
		.price {
			font-size:1rem;
		}
	}
}