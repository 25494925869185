.sidebar{
	&.sidebar-main{
		.block{
			top: 0 !important;
			margin-bottom: 0 !important;
			.content{
				padding:0 !important;
			}
		}
		@media only screen and (max-width: 767px) {
			.title.block-collapsible-nav-title{
				padding:0  0 10px 0!important;
				position: relative;
				box-sizing:content-box;
				&::after{
					display: none;
				}
				strong{
					margin:0 !important;
				}
				&:not(.active){
					display: block !important;
					background-color:$dgreen;
					box-sizing:border-box;
					padding:0 10px !important;
					width: 100%;
					height: 55px;
					position:relative;
					top:0;
					left: 0;
					right: 0;
					bottom:0;
					strong{
						position:absolute;
						display: block;
						width: 100%;
						box-sizing:border-box;
						text-decoration:none;
						padding: 0 10px;
						left: 0;
						right: 0;
						bottom:0;
						top:0;
						line-height:55px;
						color:$white;
						font-size: 	16px;
						text-align: left;
						margin-bottom: 0 !important;
						@include transition(0s);
						&::before{
							content:'';
							display: block;
							position:absolute;
							right:10px;
							top:50%;
							transform:translateY(-50%);
							background-size:15px 15px;
							width: 15px;
							height: 15px;
							background-position:center center;
							background-repeat:no-repeat;;
							background-image:url(../images/header/hamburger.svg);
						}
					}
				}
				&.active{
					display: block !important;
					background-color:$dgreen;
					box-sizing:border-box;
					padding:0 10px !important;
					width: 100%;
					height: 55px !important;
					position:fixed;
					top:0;
					left: 0;
					right: 0;
					bottom:0;
					z-index: 999999;
					strong{
						position:absolute;
						display: block;
						width: 100%;
						box-sizing:border-box;
						text-decoration:none;
						padding: 0 10px;
						left: 0;
						right: 0;
						bottom:0;
						top:0;
						line-height:55px;
						color:$white;
						font-size: 	16px;
						text-align: left;
						margin-bottom: 0 !important;
						@include transition(0s);
						&::before{
							content:'';
							display: block;
							position:absolute;
							right:10px;
							top:50%;
							transform:translateY(-50%);
							background-size:15px 15px;
							width: 15px;
							height: 15px;
							background-position:center center;
							background-repeat:no-repeat;;
							background-image:url(../images/header/close.svg);
						}
					}
				}
			}

			.content.block-collapsible-nav-content{
				&.active{
					position:fixed;
					top:0;
					left: 0;
					right: 0;
					bottom:0;
					display: block;
					width: 100%;
					height: 100vh;
					box-sizing:border-box;
					padding-top:75px !important;
					padding-bottom:10px !important;
					ul{
						height:calc(100vh - 85px);
						overflow: auto;
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
	&.sidebar-additional{
		.block-title{
			display: block !important;
			background-color:$dgreen;
			box-sizing:border-box;
			padding:0 10px !important;
			width: 100%;
			height: 55px;
			position:relative;
			top:0;
			left: 0;
			right: 0;
			bottom:0;
			strong{
				position:absolute !important;
				display: block !important;
				width: 100% !important;
				box-sizing:border-box !important;
				text-decoration:none !important;
				padding: 0 10px !important;
				left: 0 !important;
				right: 0 !important;
				bottom:0 !important;
				top:0 !important;
				line-height:55px !important;
				color:$white !important;
				font-size: 	16px !important;
				text-align: left !important;
				margin-bottom: 0 !important;
				@include transition(0s);
			}
		}
		.block-content > .actions-toolbar a{
			text-align: center;
			color:$green;
			text-decoration: none;
			display: block;
			width: 100%;
			&:hover{
				color:$lgreen;
			}
		}
		.product-items{
			.product-item-photo{
				position:static;
				display: block;
				width: 100%;
				float: none;
				*{
					display: block;
					max-width: 100%;
					height: auto;
					margin:0 auto;
					position:static;
					padding:0 !important;
					width: auto !important;
					height: auto !important;
					margin:0 auto !important;
				}
			}
			.product-item-info,
			.product-item-details{
				width: 100%;
				display: block;
				float: none;
				margin:0 !important;
				padding:0 !important;
			}
			.product-item-name{
				text-align: center;
				display: block;
				box-sizing:border-box;;
				width: 100%;
				padding-left:20px;
				padding-right:20px;
			}
			.product-item-photo{}
			.product-item-details{

			}
			.product-item-actions {
				display: block;
				width: 100%;
				margin-bottom: 10px;
				& > div{
					display: block;
					width: 100%;
					padding:0;
				}
				a,
				button{
					margin-bottom: 10px !important;
					position:static !important;
					width: 100%;
					box-sizing:border-box;
				}
			}
		}
	}
}