.product-wrap--secondary__relate__trust{
	margin-bottom: 0;
	&.product-wrap--secondary__relate__trust--1{}
	&.product-wrap--secondary__relate__trust--2{
		max-width: 1300px;
		font-size: 0;
		line-height: 0;
		margin-left:auto;
		margin-right:auto;
		margin-top: 50px;
	}
	.trust--2__box{
		@include inline;
		vertical-align: middle;
		width: 50%;
		@media only screen and (max-width: 767px) {
			width: 100%;
			margin-top: 20px;
			&:first-child{
				margin-top: 0;
			}
		}
		ul{
			@include no-format;
			display: block;
			max-width: 300px;
			margin:0 auto;
		}
	}
}

.trustpilot-wrapper{
	background-color: #FAFAFA;
	padding:50px 0;
	.container{
		max-width: 1600px !important;
		margin-left: auto !important;
		margin-right: auto !important;
		width: calc(100% - 20px) !important
	}
}
