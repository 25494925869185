/* colours */

$red: #FF0000;
$blue: #73BFE3;
$white:#ffffff;
$black:#000000;
$text:#4A4A4A;
$grey:#4A4A4A;
$lgreen:#93CBBE;
$green:#459280;
$mgreen:#244856;
$dgreen: #15313C;
$gold:#B6984B;
$pink:#7D446D;
$border:#BBBBBB;
$bordero:rgba(187, 187, 187, .3);


// Pixels
$radius: 5px;

// Fonts
$proxima: proxima-nova, sans-serif;
$abril: abril-text, serif;
$work: work-sans, sans-serif;
$trade: trade-gothic-next, sans-serif;