div.mage-error,
div.field-error{
	display: none !important;
}
form{
	max-width: 100%;
	width: 100%;
	margin:0 0 20px;
	box-sizing:border-box;
	padding:0 !important;
	background-color: transparent !important;
	text-align: left !important;
	.payment-methods{
		margin:0 auto !important;
		.step-title,
		.payment-method-title,
		.payment-method-content{
			padding-left:0;
			padding-right:0;
		}
	}
}
.cart-summary .block .item-options .field .radio+.label{
	overflow: visible !important;
	display: block !important;
	box-sizing:border-box !important;

}
.note{
	text-align: center !important;
}
button.action:not(.search):not(.close),
.gform_footer input,
.product-item-actions .action,
.action.primary.continue{
	background-color: $dgreen;
	border-color:$dgreen;
	color:$white !important;
	&:hover{
		color:$dgreen !important;
		background-color:$white;
	}
}
.cart.table-wrapper{
	.item-options{
		margin-top: 0 !important;
	}
	*{
		font-size:16px !important;
		line-height: 1.2 !important;
		font-weight: 300 !important;
		text-align: left !important;
		color:$text !important;
	}
	.col.qty .field.qty .control.qty{
		width: auto !important;

		@media only screen and (max-width: 767px) {
			*{
				line-height: 50px !important;
			}
		}
	}
	.actions-toolbar{
		margin-bottom: 10px !important;
		@media only screen and (max-width: 767px) {
			text-align: right !important;
			a{
				position:relative;
				&.towishlist{
					right: 33px !important;
				    width: auto !important;
				    position: absolute;
				    left: auto !important;
				    margin-right: 0 !important;
				}
			}
		}
	}
	.product-item-name{

	}
}
.cart.main.actions{
	input,
	button,
	a{

		@media only screen and (min-width: 768px) {
			@include inline;
			vertical-align: middle;
			width: auto;
			margin-left: 20px;
		}
		@media only screen and (max-width: 767px) {
			width: 100%;
			margin:0 auto 10px;
			&:last-child{
				margin-bottom: 0 !important;
			}
		}
	}
}
.actions-toolbar button[type="submit"].button.action{
	width: 100%;
	line-height: 33px !important;
	padding:7px 20px !important;
	font-size: 16px !important;
	*{
		font-size: inherit !important;
		line-height: inherit !important;
	}
}
.primary{
	width: 100% !important;
	float:none !important;
}
label.label,
label.label *{
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	font-family: $proxima;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
select{
	line-height: 50px;
	width: 100%;
	display: block;
	box-sizing: border-box;
	padding:0 15px;
	font-size: 16px;
	border:none;
	box-shadow:none;
	appearance:none;
	height: 50px;
	@include transition(0.2s);
	border-radius:$radius;
	@include transition(0.2s);
	background-position: right 15px center;
	&.mage-error{
		background-color:salmon;
	}
	&:focus{
		&:placeholder,
		&::placeholder{
			opacity:0;
		}
	}
}
.field._error{
	input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
	select,
	textarea{

			background-color:salmon;
	}
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
select{
	line-height: 48px;
	width: 100%;
	display: block;
	box-sizing: border-box;
	padding:0 15px;
	font-size: 16px;
	border:none;
	box-shadow:none;
	appearance:none;
	height: 50px;
	@include transition(0.2s);
	border-radius:$radius;
	border:1px solid $border;
	@include transition(0.2s);
	background-position: right 15px center;
	&.mage-error{
		background-color:salmon;
	}
	&:focus{
		&:placeholder,
		&::placeholder{
			opacity:0;
		}
	}
}
textarea{
	line-height: 1.8;
	width: 100%;
	display: block;
	box-sizing: border-box;
	padding:10px 15px;
	font-size: 16px;
	border:none;
	box-shadow:none;
	appearance:none;
	height: auto;
	@include transition(0.2s);
	border-radius:$radius;
	border:1px solid $border;
	@include transition(0.2s);
	background-position: right 15px center;
}
.choice{
	display: block;
	position: relative;
	cursor: pointer;
	li{
		position:relative;
	}
	label{
		display: block !important;
	}
	input[type="checkbox"],
	input[type="radio"]{
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		display: block;
		width: 100%;
		height: 100%;
		z-index:2;
		opacity:0;
		& + label{
			display: block;
			position:relative;
			padding-left:45px;
			width: 100%;
			text-align: left;
			z-index: 1;
			box-sizing:border-box;;
			&::before{
				content:'';
				display: block;
				position:absolute;
				top:0;
				left: 1px;
				border:1px solid $border;
				border-radius:$radius;
				width: 26px;
				height: 26px;
				box-sizing:border-box;
				background-size:15px 15px;
				background-repeat:no-repeat;
				background-position: center center;
			}
		}
		&:checked + label{
			&::before{
				background-image:url(../images/input/crossed.svg);
			}
		}
	}
}