.promos{
	margin-bottom: 0;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	width: calc(100% - 20px);
	& + .promos{
		margin-bottom: 35px;
		@media only screen and (max-width: 767px) {
			margin-bottom: 10px;
		}
	}
	ul{
		@include no-format;
		text-align: center;
		font-size: 0;
		line-height: 0;
		margin-left: -10px;
		margin-right: -10px;
		max-width: calc(100% + 20px);
		width: calc(100% + 20px);
		li{
			box-sizing:border-box;;
			padding:0 10px;
			@include inline;
			vertical-align: middle;
			text-align:center;
			margin-bottom: 25px !important;
			@media only screen and (max-width: 767px) {
				width: 50%;
				&:last-child:nth-child(odd){
					width: 100%;
				}
			}
			@media only screen and (max-width: 479px) {
				padding:0 5px;
				margin-bottom: 10px !important;
			}
			a{
				display: block;
				width: 100%;
				text-decoration: none;
				color:white;
			}
		}
	}
	@media only screen and (min-width: 768px) {
		&--4 {
			ul li{
				width: 25%;
			}
		}
		&--3 {
			ul li{
				width: 33.333%;
				margin-bottom: 25px;
			}
			&--category_alt {
				ul li{
					&:nth-child(3n + 1),
					&:nth-child(3n + 2){
						width: 25%
					}	
					&:nth-child(3n + 3){
						width: 50%
					}
				}
				&.promos--3--change_dir ul li{

					&:nth-child(3n + 3),
					&:nth-child(3n + 2){
						width: 25%
					}	
					&:nth-child(3n + 1){
						width: 50%
					}
				}
			}
		}
		&--2 ul li{
			width: 50%;
		}
	}
	&__image{
		width: 100%;
		@include thumbnail;
		height: 325px;
		position:relative;
		@media only screen and (max-width: 1050px) {
			height: 200px;
		}
		@media only screen and (max-width: 767px) {
			height: 140px;
		}

		.promos__overlay{
			background-color:rgba(69, 146, 128, 0.6);
			position:absolute;
			top:0;
			left: 0;
			right:0;
			bottom:0;
			display: block !important;
			width: 100%;
			height: 100%;
			visibility: hidden;
			opacity:0;
			@include transition(0.2s);
			&:hover{
				
			}
			.button{
				position:absolute;
				top:50%;
				left: 50%;
				transform:translate(-50%,-50%);
			}
		}
		&:hover .promos__overlay{

			visibility: visible;
			opacity:1;
		}
	}
	&__text{
		padding:15px 10px;
		box-sizing:border-box;
		background-color: #EEEEEE;
		h2{
			font-family: $abril;
			font-size: 24px;
			line-height: 32px;
			font-weight:400;
			color: #459280;	
			@media only screen and (max-width: 767px) {
				font-size: 18px;
				line-height: 25px;
			}
		}
	}
}

.promos_colour{
	margin-bottom: 0;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	width: calc(100% - 20px);
	margin-bottom: 40px;
	& + .promos_colour{
		margin-bottom: 35px;
		@media only screen and (max-width: 767px) {
			margin-bottom: 20px;
		}
	}
	ul{
		@include no-format;
		text-align: center;
		font-size: 0;
		line-height: 0;
		margin-left: -10px;
		margin-right: -10px;
		max-width: calc(100% + 20px);
		width: calc(100% + 20px);
		li{
			box-sizing:border-box;;
			padding:0 10px;
			@include inline;
			vertical-align: middle;
			text-align:center;
			margin-bottom: 25px !important;
			width: 50%;
			@media only screen and (max-width: 1050px) {
				width: 100%;
				margin-bottom: 10px !important;
			}
			text-align: center;
		}
	}
	&--left,
	&--right{
		width: 50%;
		float: right;
		@include thumbnail;
		box-sizing:border-box;
		@media only screen and (max-width: 767px) {
			width: 100%;
			float: none !important;
		}
	}
	&--left{
		padding:45px 30px;
		float: right;
		h1,h2,h3,h4,h5,h6{
			color:$white;
			font-size: 32px;
			font-family: $abril;
			font-weight: 400;
			margin-bottom: 15px;
			@media only screen and (max-width: 767px) {
				font-size: 24px;
				margin-bottom: 10px;
			}
		}
		p{
			color:$white;
			font-family: $abril;
			font-weight: 400;
			margin-bottom: 20px;
			@media only screen and (max-width: 1050px) {
				display: block;
				max-width: 300px;
				margin-left: auto;
				margin-right: auto;
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
		@media only screen and (max-width: 1050px) {
			.button{
				min-width: 0px;
				width: 100%;
				max-width: 192px;
			}
		}
		@media only screen and (max-width: 767px) {
			padding:15px 10px 25px 10px;
		}
	}
	@media only screen and (max-width: 767px) {
		&--right{
			width: 100%;
			padding-bottom: 50%;

		}
	}
}
.promos_overlay{
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	width: calc(100% - 20px);
	margin-bottom: 100px;
	@media only screen and (max-width: 767px) {
		margin-bottom: 50px;
	}
	ul{
		@include no-format;
		text-align: center;
		font-size: 0;
		line-height: 0;
		margin-left: -10px;
		margin-right: -10px;
		width: calc(100% + 20px);
		li{
			box-sizing:border-box;;
			margin:0 10px;
			@include inline;
			vertical-align: middle;
			text-align:center;
			margin-bottom: 25px !important;
			width: calc(50% - 20px);
			@media only screen and (max-width: 1050px) {
				width: calc(100% - 20px);
				margin-bottom: 10px !important;
			}
			text-align: center;
			
			& > div{
				padding:45px 30px;
				h1,h2,h3,h4,h5,h6{
					color:$white;
					font-size: 36px;
					font-family: $abril;
					font-weight: 400;
					margin-bottom: 20px;
					@media only screen and (max-width: 767px) {
						font-size: 24px;
						margin-bottom: 15px;
					}
				}
				p{
					color:$white;
					font-family: $abril;
					font-weight: 400;
					@media only screen and (min-width: 1051px) {
						margin-bottom: 30px;
					}
					@media only screen and (max-width: 1050px) {
						display: block;
						max-width: 300px;
						margin-left: auto;
						margin-right: auto;
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
				@media only screen and (max-width: 1050px) {
					.button{
						min-width: 0px;
						width: 100%;
						max-width: 192px;
					}
				}
				@media only screen and (max-width: 767px) {
					padding:15px 10px 25px 10px;
				}
			}
		}
	}
}