.blog-single__hero{
	display: block;
	width: 100%;
	height: 500px;
	margin-bottom: 20px;
	position: relative;
	& + .breadcrumbs{
		margin-bottom: 40px;
		margin-top: 0;
	}
	&::before{
		display: block;
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
		opacity:0.5;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
	}
	&__table{
		@include table;
		max-width: 720px;
		position:relative;
		z-index: 2;
	}
	&__cell{
		@include cell;
	}
	*{
		color: $white;
		text-align: center;
	}
	h1,h2,h3,h4,h5,h6{
		margin-bottom: 15px;
	}
	ul{
		@include no-format;
		li{
			@include inline;
			vertical-align: middle;
			margin-bottom: 0;
			margin:0 5px;
			&.date{
				@include inline;
				vertical-align: middle;
				font-size: 12px;
				line-height: 26px;
				color:$white;
			}
			&.cat{
				font-size: 0;
				line-height: 0;
				@include inline;
				vertical-align: middle;
				a{
					margin: 0 2px 2px 0;
					@include inline;
					vertical-align: middle;
					padding:2px 8px;
					font-size: 12px;
					line-height: 22px;
					background-color: #7D446D;
					color:$white;
					text-transform: uppercase;
					text-decoration: none;
					&:hover{
						background-color:$green;
					}
				}
			}
		}
	}
}