

#maincontent aside{
	background-color:#FAFAFA !important;
	box-sizing:border-box;
	@media only screen and (min-width: 768px) {
		padding:100px 10px;
	}
	a{
		color:$green;
	}
	.shipping-information .shipping-information-title > span{
			color:$green;
			font-size: 28px;
			line-height: 35px;
			font-family:$abril;
			display: block;
			width: 100%;
			margin-bottom: 20px;
	}
	.opc-block-summary,
	.opc-block-shipping-information {
		padding:20px 0;
		@media only screen and (max-width: 767px) {
			padding:20px 20px;
		}
		& > .title,
		.shipping-information-title,
		.shipping-information-content{
			margin-bottom: 45px;
			max-width:652px;
			margin: 0 auto 45px;
			display: block;
			width: 100%;
			@include clearfix;
			float: none;
			position:static !important;
			button,
			button *{
				text-transform: capitalize !important;
			}
			@media only screen and (min-width: 900px) {
				span{
					float:left !important;
					width: auto;
				}
				button{
					position:static !important;
					float: right !important;
					span{
						display: block !important;
						width: 100% !important;
						text-align:center !important;
						float: none !important;
					}
					text-align: center !important;
					width: auto !important;
				}
			}
			@media only screen and (max-width: 899px) {
				margin-bottom: 20px;
				span{
					display: block;
					width: 100%;
				}
				button{
					display: block;
					width: 100%;
					float: none;
					text-align: center;
					max-width: 100%;
					position:static !important;
				}
			}
		}
		.table{
			margin-bottom: 45px;
			max-width:652px;
			margin: 0 auto 20px;
			display: block;
			width: 100%;
			tbody{
				display: block;
				width: 100%;
			}
			tr{
				display: block;
				width: 100%;
				@include clearfix;
			}
			th,
			td{
				display: block;
				width: 50%;
				float: left;
				box-sizing:border-box;;
			}
		}
		@media only screen and (max-width: 767px) {
			.minicart-items-wrapper{
				height: auto !important;
				max-height: none !important;
			}
		}
	}
	@media only screen and (min-width: 768px) {
		position:absolute;
		top:66px;
		left:50%;
		width: 50vw;
		height: calc(100% - 66px);
		margin-top: 0;
		bottom:0;
		overflow: auto;
		.opc-block-summary {
			top:0;
			left: 0;
			right: 0;
			bottom:0;
			box-sizing: border-box;
			background-color:transparent !important;
			.minicart-items-wrapper{
				max-height: 90%;
			}
		}
	}
	.items-in-cart {
		max-width:652px;
		margin: 0 auto;
		width: 100%;
		.title, 
		.title *{
			color:$text;
			font-family: $proxima;
			font-size: 18px;
			line-height: 35px;
			text-transform: uppercase;
			&::after{
				display: none;
			}
		}
		.minicart-items{
			display: block !important;
		}
		.product-item{

		}
		.product-image-container{
			width: 120px !important;
			height: auto !important;
			display: block !important;
			@media only screen and (max-width: 767px) {
				width: 100% !important;
				float: none;
				display: block;
				margin-bottom: 0;
				max-width: 150px;
			}
			*{
				width: 100% !important;
				height: auto !important;
			}
		}
		.details-qty,
		.details-qty *{
			font-weight: 300;
			font-size: 16px;
			line-height: 20px;
			color:$text;
			margin-top:0;
		}
		.details-qty{
			margin-bottom: 12px;
			margin-top: 5px;
			display: block;
			width: 100%;
			text-align: left;
		}
		.product-item-name,
		.product-item-name *{
			margin-bottom: 0;
			font-size: 18px;
			line-height: 1.2;
			font-family: $abril;
			font-weight:400;
			display: block;
			width: 100%;
			text-align: left;
		}
		.cart-price{
			font-size: 18px;
			font-weight: bold;
			font-family: $proxima;
			line-height: 1.2;
			margin:0;
			*{
				font-size: inherit;
				line-height: inherit;
				font-family: inherit;
				font-weight: inherit;
			}
			padding-top:20px;
		}
		.product-item-name{
			padding-top:20px;
		}
		.product-item-details{
			padding-left:145px;
			@media only screen and (max-width: 767px) {
				width: 100%;
				float:none;
				display: block;
				padding-left:0;
			}
		}
		.product.options{
			.toggle{
				color:$green;
				cursor:pointer;
				font-size: 16px;
				line-height: 20px;
				font-weight: 300;
				text-decoration: underline;
				margin-bottom: 20px;
				&:hover{
					color:$dgreen;
				}
				&::after{
					display: none;
				}
			}
			.item-options{
				@include clearfix;
				dt,
				dd{
					display: block;
					float: left;
					border-bottom: 1px solid $border;
					padding-bottom:5px;
					margin-bottom: 5px;
				}
				dt{
					width: 100px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;;
				}
				dd{
					width: calc(100% - 100px);
				}
			}
		}
	}
	h1,h2,h3,h4,h5,h6,
.title,
.step-title{
	color:$green;
	font-size: 28px;
	line-height: 35px;
	font-family:$abril;
	margin-bottom: 20px;
}
.step-title{
	margin-bottom: 40px;
	padding-bottom:0;
	border-bottom:none;
}
.modal-inner-wrapper{
	background-color:transparent !important;
	box-shadow:none !important;
	padding:0 !important;
	.opc-block-summary{
		background: transparent !important;
	}
}
div.mage-error,
div.field-error{
	display: none !important;
}

button:not(.action-close){
	&::before,
	&::after{
		display: none !important;
	}
	float: none !important;
	display: block !important;
	width: 100% !important;
	box-sizing:border-box !important;
	background-color: $dgreen !important;
	color:$white !important;
	border:none !important;
	@include transition(0.2s);
	font-size: 16px !important;
	line-height: 28px !important;
	font-weight: bold !important;
	font-family: $proxima !important;
	color:$white !important;
	min-width: 160px !important;
	padding:9px 20px !important;
	text-align: center !important;
	@include inline;
	vertical-align: middle !important;
	border-radius:$radius !important;
	box-sizing:border-box !important;
	border:2px solid $dgreen !important;
	box-shadow:none !important;
	text-decoration: none !important;
	margin:0 auto 10px !important;
	&:last-child{
		margin-bottom: 0 !important
	}
	*{
		font-size: inherit !important;
		line-height: inherit !important;
		font-weight: inherit !important;
		color:inherit !important;
		width: auto !important;
		height: auto !important;
		clip: none !important;
		position:static !important;
		overflow: visible !important;
		text-transform: none !important;
		text-decoration: none !important;
	}
	&:hover{
		color:$dgreen !important;
		background-color:$white !important;
	}
}
form{
	max-width: 100%;
	width: 100%;
	margin:0 0 20px;
	box-sizing:border-box;
	padding:0 !important;
	background-color: transparent !important;
}
.actions-toolbar button[type="submit"].button.action{
	width: 100%;
	line-height: 33px !important;
	padding:7px 20px !important;
	font-size: 16px !important;
	*{
		font-size: inherit !important;
		line-height: inherit !important;
	}
}
.primary{
	width: 100% !important;
	float:none !important;
}
label.label,
label.label *{
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	font-family: $proxima;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
select{
	line-height: 50px;
	width: 100%;
	display: block;
	box-sizing: border-box;
	padding:0 15px;
	font-size: 16px;
	border:none;
	box-shadow:none;
	appearance:none;
	height: 50px;
	@include transition(0.2s);
	border-radius:$radius;
	@include transition(0.2s);
	background-position: right 15px center;
	&.mage-error{
		background-color:salmon;
	}
	&:focus{
		&:placeholder,
		&::placeholder{
			opacity:0;
		}
	}
}
.field._error{
	input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
	select,
	textarea{

			background-color:salmon;
	}
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
select{
	line-height: 48px;
	width: 100%;
	display: block;
	box-sizing: border-box;
	padding:0 15px;
	font-size: 16px;
	border:none;
	box-shadow:none;
	appearance:none;
	height: 50px;
	@include transition(0.2s);
	border-radius:$radius;
	border:1px solid $border;
	@include transition(0.2s);
	background-position: right 15px center;
	&.mage-error{
		background-color:salmon;
	}
	&:focus{
		&:placeholder,
		&::placeholder{
			opacity:0;
		}
	}
}
textarea{
	line-height: 1.8;
	width: 100%;
	display: block;
	box-sizing: border-box;
	padding:10px 15px;
	font-size: 16px;
	border:none;
	box-shadow:none;
	appearance:none;
	height: auto;
	@include transition(0.2s);
	border-radius:$radius;
	border:1px solid $border;
	@include transition(0.2s);
	background-position: right 15px center;
}
.choice{
	display: block;
	position: relative;
	cursor: pointer;
	li{
		position:relative;
	}
	label{
		display: block !important;
	}
}
footer{
	padding:20px !important;
}
}