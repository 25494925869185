.full-width-image{
	max-width:1600px;
	display: block;
	margin-bottom: 60px;
	margin-left:auto;
	margin-right: auto;
	display: block;
	width: calc(100% - 20px);
	a{
		display: block;
		width: 100%;
	}
	img{
		display: block;
		width: 100%;
		height: auto;
	}
	.is-video{
		position: relative;
		&::before{
			content:'';
			display: block;
			max-width:78px; 
			max-height:78px;
			width: 100%;
			height: 100%;
			position:absolute;
			top:50%;
			left: 50%;
			transform:translate(-50%,-50%);
			background-repeat: no-repeat;
			background-position: center center;
			background-size:78px 78px;
			background-image:url(../images/icons/video.svg);
		}
	}
}